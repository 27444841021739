import React, { useEffect, useState } from 'react'
import { GET } from '../../helpers/http'
import { Stack, Typography } from '@mui/material'
import QRCode from 'qrcode.react'

const AreaQr = React.forwardRef((props, ref) => {
    const { qrCode, setPrintFlag, envType } = props
    const [qrObj, setQrObj] = useState({})

    useEffect(() => {
        if (qrCode) {
            GET(`${process.env.REACT_APP_URL}qrcodes/${qrCode}?action=encrypt&type=${envType}`)
                .then((response) => {
                    if (response?.status === 200) {
                        setQrObj(response?.data)
                        setPrintFlag(true)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [qrCode])

    return (
        <Stack sx={{
            height: "97vh",
            width: "96vw",
            m: 2,
            p: 3
        }}
            justifyContent={'space-between'}
            alignItems={'center'}
            border="solid"
            ref={ref}
        >
            <Stack
                sx={{
                    height: '15%',
                    width: '100%'
                }}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <img
                    src={qrObj?.company?.logo}
                    alt="company_logo"
                    style={{
                        height: "55%"
                    }}
                />
                {/* <Typography
                    // variant='h4'
                    fontSize={'2.8vh'}
                    fontWeight={'bold'}
                >{qrObj?.company?.name}</Typography> */}
            </Stack>
            <Stack
                sx={{
                    height: '40%',
                    width: '100%'

                }}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <QRCode
                    id="qrcode"
                    value={qrObj?.hashUrl}
                    size={500}
                    style={{
                        height: "30vh",
                        width: "30vh",
                    }}
                />
                <Typography
                    // variant='h5'
                    fontSize={'2.5vh'}
                >{qrObj?.area?.name}</Typography>

                <Typography                             // here
                    // variant='h5'
                    fontSize={'2.5vh'}
                >{qrObj?.floor?.name}-{qrObj?.zone?.name}
                </Typography>
                {/* to here */}
            </Stack>
            <Stack
                sx={{
                    height: '25%',
                    width: '50%'
                }}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography
                    // fontWeight={'bold'}
                    // variant='h5'
                    fontSize={'2.5vh'}
                    textAlign={'center'}
                >Scan the QR code from your phone to report a fault and feedback</Typography>
                <img
                    src={qrObj?.commonNhanceLogo}
                    alt="nhance_logo"
                    style={{
                        height: "15%"
                    }}
                />
            </Stack>
        </Stack>
    )
})

export default AreaQr