import React from 'react'

export const sensorDeviceValidation = (sensorData, addfield) => {

    let errObj = {
        "sensorId": {
            condition: sensorData?.sensorId === "",
            errorText: "Please enter the Sensor ID"
        },
        "vendorId": {
            condition: sensorData?.vendorId === "",
            errorText: "Please enter the Vendor ID"
        },
        "subarea": {
            condition: sensorData?.subarea === "",
            errorText: "Please enter the sub area"
        },
        "uCode": {
            condition: sensorData?.uCode === "",
            errorText: "Please enter the uCode"
        },
        "sensor": {
            condition: sensorData?.sensor === "" || sensorData?.sensor === undefined,
            errorText: "Please select the sensor"
        },
        "vendor": {
            condition: sensorData?.vendor === "" || sensorData?.vendor === undefined,
            errorText: "Please select the vendor"
        },
        "head": {
            condition: sensorData?.head === "" || sensorData?.head === undefined,
            errorText: "Please select the head"
        },
        "location": {
            condition: sensorData?.location === "" || sensorData?.location === undefined,
            errorText: "Please select the location"
        },
        "building": {
            condition: sensorData?.building === "" || sensorData?.building === undefined,
            errorText: "Please select the building"
        },
        "floor": {
            condition: sensorData?.floor === "" || sensorData?.floor === undefined,
            errorText: "Please select the floor"
        },
        "zone": {
            condition: sensorData?.zone === "" || sensorData?.zone === undefined,
            errorText: "Please select the zone"
        },
        "subzone": {
            condition: sensorData?.subzone === "" || sensorData?.subzone === undefined,
            errorText: "Please select the subzone"
        },
        "area": {
            condition: sensorData?.area === "" || sensorData?.area === undefined,
            errorText: "Please select the area"
        },
        "connection": {
            condition: sensorData?.connection === "" || sensorData?.connection === undefined,
            errorText: "Please select the connection"
        },
        "status": {
            condition: sensorData?.status === "" || sensorData?.status === undefined,
            errorText: "Please select the status"
        },
        "areatype": {
            condition: sensorData?.areatype === "" || sensorData?.areatype === undefined,
            errorText: "Please select the areatype"
        }
    }

    let obj = { "feature": [], "indice": [] }

    addfield.forEach((ele, i) => {
        obj['feature'].push({
            index: i,
            condition: !sensorData.feature[i]?.featureId,
            errorText: "Please select the feature"
        })
        obj['indice'].push({
            index: i,
            condition: !sensorData.feature[i]?.indexId,
            errorText: "Please select the Indice"
        })
    })

    errObj = { ...errObj, ...obj }
    let error = {}

    Object.keys(errObj).forEach((name) => {
        if (name === 'feature' || name === 'indice') {
            let customError = {}
            errObj[name].forEach((ele, i) => {
                if (ele.condition) customError = { ...customError, [i]: ele.errorText }
            })
            if (Object.keys(customError).length) error[name] = customError
        }
        else if (errObj[name]?.condition) error[name] = errObj[name]?.errorText
    })

    return error
}


export const fieldValidation = (addfield, featureList, indiceList) => {

    let obj = { "feature": [], "indice": [] }

    addfield.forEach((ele, i) => {
        obj['feature'].push({
            index: i,
            condition: !featureList[i],
            errorText: "Please select the feature"
        })
        obj['indice'].push({
            index: i,
            condition: !indiceList[i],
            errorText: "Please select the Indice"
        })
    })
    let errObj = {}
    Object.keys(obj).forEach((arr) => {
        let customError = {}
        obj[arr].forEach((ele, i) => {
            if (ele.condition) customError = { ...customError, [i]: ele.errorText }
        })
        if (Object.keys(customError).length) errObj[arr] = customError
    })
    return errObj
}