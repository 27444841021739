import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Typography, IconButton, Button, Box } from '@mui/material'
import { Download, } from '@mui/icons-material'
import { asyncGenerateQrCodes, asyncGetAllQrcodes } from '../../Redux/Actions/qrcodesAction'
import Heading from '../UI/Heading'
import Datatable1 from '../Main/DataTable1'
import { asyncGetAllMasterData } from '../../Redux/Actions/masterListAction'
import { useReactToPrint } from 'react-to-print'
import AreaQr from '../Downloadable/AreaQr'

const QrCodePage = () => {
    const [viewQr, setViewQR] = useState({})
    const [envType, setEnvType] = useState("")
    const [printFlag, setPrintFlag] = useState(false)


    const dispatch = useDispatch()
    const qrCodesList = useSelector(state => state.qrcode?.data)
    const areasList = useSelector(state => state?.masterList?.area?.data)
    useEffect(() => {
        dispatch(asyncGetAllQrcodes())
        dispatch(asyncGetAllMasterData('area'))
    }, [])


    const triggerDownload = ({ type, data }) => {
        setEnvType(type)
        setViewQR(data)
    }

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        // pageStyle: `@media print {
        //     @page {
        //         size: A4 portrait;
        //         margin  : 0;
        //     }
        // }`,
        content: () => componentRef.current,
        documentTitle: `AreaQr-${viewQr?.area?.name || "NA"}-${viewQr?.subzone?.name || "NA"}-${viewQr?.floor?.name || "NA"}-${viewQr?.building?.name || "NA"}`
    });

    useEffect(() => {
        if (printFlag === true) {
            handlePrint()
            setPrintFlag(false)
            setEnvType("")
        }

    }, [printFlag])

    const handleGenerateQrCode = () => {
        dispatch(asyncGenerateQrCodes())
    }

    const columns = [{
        field: 'SrNo',
        headerName: 'Sr. No.',
        width: 100,
    },
    {
        field: 'location',
        headerName: 'Location',
        width: 100,
        valueGetter: (params) => params?.row?.location?.name ? params?.row?.location?.name : "NA"
    },
    {
        field: 'building',
        headerName: 'Building',
        width: 200,
        valueGetter: (params) => params?.row?.building?.name ? params?.row?.building?.name : "NA"
    },
    {
        field: 'floor',
        headerName: 'Floor',
        width: 150,
        valueGetter: (params) => params?.row?.floor?.name ? params?.row?.floor?.name : "NA"
    },
    {
        field: 'zone',
        headerName: 'Zone',
        width: 100,
        valueGetter: (params) => params?.row?.zone?.name ? params?.row?.zone?.name : "NA"
    },
    {
        field: 'subzone',
        headerName: 'Subzone',
        width: 100,
        valueGetter: (params) => params?.row?.subzone?.name ? params?.row?.subzone?.name : "NA"
    },
    {
        field: 'area',
        headerName: 'Area',
        width: 400,
        valueGetter: (params) => params?.row?.area?.name ? `${params?.row?.area?.name} - ${params?.row?.area?._id}` : "NA"
    },
    {
        field: 'Actions',
        renderCell: (cellValues) => {
            return (
                <Stack direction={'row'} spacing={1}>
                    <IconButton
                        size="small"
                        onClick={() => triggerDownload({ type: 'dev', data: cellValues?.row })}
                    >
                        <Download fontSize="small" sx={{ color: '#1976D2' }} />
                        <Typography color={'#1976D2'} variant={'caption'}>
                            &nbsp;Dev QrCode
                        </Typography>
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => triggerDownload({ type: 'prod', data: cellValues?.row })}
                    >
                        <Download fontSize="small" sx={{ color: '#1976D2' }} />
                        <Typography color={'#1976D2'} variant={'caption'}>
                            &nbsp;Prod QrCode
                        </Typography>
                    </IconButton>
                </Stack>
            )
        },
        width: 250,
        sortable: false,
    },
    ]

    const rows = qrCodesList.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })

    return (
        <Box>
            <Heading title="QR Codes" />
            <Box display="flex" justifyContent="flex-end" height={"60px"} alignItems={"center"} bgcolor={"#f1f1f1"}>
                <Button
                    variant="filled"
                    disabled={areasList.length === qrCodesList.length}
                    sx={{
                        maxWidth: "220px",
                        width: "200px",
                        // alignSelf: 'end',
                        mr: 3,
                        // mb: 2,
                        // mt: 1,
                        backgroundColor: '#DC4D28',
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    onClick={handleGenerateQrCode}
                >
                    Generate QrCode
                </Button>
            </Box>
            <Datatable1 rows={rows} columns={columns} pageSize={15} checkBox={false} rowsPerPage={15} />
            {envType && Object.keys(viewQr).length > 0 && <Box
                sx={{ display: 'none' }}
            >
                <AreaQr
                    qrCode={viewQr?.qrCode}
                    setPrintFlag={setPrintFlag}
                    envType={envType}
                    ref={componentRef}
                />
            </Box>}
        </Box>
    )
}

export default QrCodePage