import { Box, Divider, Typography } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import ReactJSON from 'react-json-view'
import { useSelector } from "react-redux"

const Config = (props) => {
    // const match = useRouteMatch()
    const { service, tenantId } = props
    // const { handleSnackbarOpen } = props

    // const [configData, setConfigData] = useState({})
    // const tenant = localStorage.getItem('tenantName').split(' ').join('').toUpperCase()
    const configData = useSelector(state => state.configuration[service])

    // const tenantIdList = {
    //     "NHANCE": "NHANHANCE23",
    //     "ADRIANSE": "NHAADRIANSE23",
    //     "OPENTEXT": "NHAOPENTEXT23",
    //     "FIDELITY": "NHAFIDELITY23",
    //     "FIDELITYV1": "NHAFIDELITY23",
    //     "TATADIGITAL": "NHATATADIGITAL23",
    //     "CULTFIT": "NHACULTFIT23"
    // }

    // let tenantId = ''

    // if (Object.keys(tenantIdList).includes(tenant)) {
    //     tenantId = tenantIdList[tenant]
    // }

    // const baseArr = [
    //     {
    //         title: 'twinapi',
    //         url: process.env.REACT_APP_TWIN_API_BASE_URL
    //     },
    //     {
    //         title: 'assetmanagement',
    //         url: process.env.REACT_APP_ASSET_BASE_URL
    //     },
    //     {
    //         title: 'meetingroom',
    //         url: process.env.REACT_APP_MEETING_ROOM_BASE_URL
    //     },
    //     {
    //         title: 'faultreport',
    //         url: process.env.REACT_APP_FAULT_REPORT_BASE_URL
    //     },
    //     {
    //         title: 'feedback',
    //         url: process.env.REACT_APP_FEEDBACK_BASE_URL
    //     },
    //     {
    //         title: 'deskbooking',
    //         url: process.env.REACT_APP_DESK_BOOKING_BASE_URL
    //     }
    // ]

    // let baseUrl = baseArr.find(ele => ele.title === service)
    // // console.log({ baseUrl, service, tenantId })

    // useEffect(() => {
    //     if (baseUrl && tenantId) {
    //         axios.get(`${baseUrl.url}v1/saas/tenant/config`, {
    //             headers: {
    //                 "x-tenant-id": tenantId
    //             }
    //         })
    //             .then((res) => {
    //                 setConfigData(res.data)
    //             })
    //             .catch((err) => {
    //                 setConfigData({})
    //                 const error = err.response
    //                 if (error.status === 404) {
    //                     setConfigData(error.data)
    //                 }
    //                 else if (error.status === 400) {
    //                     handleSnackbarOpen("Invalid Tenant", "error")
    //                 }
    //                 else {
    //                     console.log(error?.data?.error);
    //                     handleSnackbarOpen("Something went wrong", "error")
    //                 }
    //             })
    //     } else if (!tenantId) {
    //         setConfigData('')
    //         handleSnackbarOpen("Invalid Tenant", "error")
    //     }
    // }, [service])

    return <Box sx={{ backgroundColor: "white", height: "64px", color: "black", fontSize: "130%", fontWeight: "bold" }} p={5} >
        {
            Object.keys(configData).length > 0 && <Box>
                <Typography sx={{ color: "black", fontSize: "120%", fontWeight: "bold", marginBottom: "15px" }} >TenantID - {tenantId}</Typography>
                <ReactJSON style={{ marginLeft: "30px" }} src={configData} name={false} displayDataTypes={false} displayObjectSize={false} />
            </Box>
        }
    </Box>

}

export default Config