const { GET_ALL_SENSOR_DEVICES, GET_SELECTED_SENSOR_DEVICE } = require("../Actions/sensorDeviceAction")


const initialState = {
    data: [],
    selectedDevice: {}
}

const sensorDeviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SENSOR_DEVICES: {
            return { data: action.payload, selectedDevice: {} }
        }
        case GET_SELECTED_SENSOR_DEVICE: {
            return { ...state, selectedDevice: action.payload }
        }
        case "LOGOUT": {
            return initialState
        }
        case "REFRESH": {
            return initialState
        }
        default: return state
    }
}

export default sensorDeviceReducer