import { DELETE, GET } from "../../helpers/http"

export const GET_ALL_DATA = "GET_ALL_DATA"
export const DELETE_MASTER_DATA = "DELETE_MASTER_DATA"
export const GET_SELECTED_MASTER_DATA = "GET_SELECTED_MASTER_DATA"

const getAllMasterData = (data) => {
    return { type: GET_ALL_DATA, payload: data }
}

const deleteMasterData = (data) => {
    return { type: DELETE_MASTER_DATA, payload: data }
}

const getMasterData = (data) => {
    return { type: GET_SELECTED_MASTER_DATA, payload: data }
}

export const asyncGetAllMasterData = (listType) => {
    return (dispatch) => {

        const list = listType === "currency" ? "currencies" : `${listType}s`
        GET(`${process.env.REACT_APP_URL}${list}`)
            .then((response) => {
                dispatch(getAllMasterData({ listType, data: response.data }))
            })
            .catch((error) => {
                const status = error?.response?.status
                const errorArray = error?.response?.data
                console.log(error)
            })
    }
}

export const asyncDeleteMasterData = ({ id, listType }) => {
    return (dispatch) => {
        DELETE(`${process.env.REACT_APP_URL}${listType}/${id}`)
            .then((response) => {
                dispatch(deleteMasterData({ listType, data: response.data?._id }))
            })
            .catch((error) => {
                const status = error?.response?.status
                const errorArray = error?.response?.data
                console.log(error)
            })
    }
}

export const asyncGetSelectedMasterData = ({ id, listType }) => {
    return (dispatch) => {
        GET(`${process.env.REACT_APP_URL}${listType}/${id}`)
            .then((response) => {
                dispatch(getMasterData({ listType, data: response.data }))
            })
            .catch((error) => {
                const status = error?.response?.status
                const errorArray = error?.response?.data
                console.log(error)
            })
    }
}
