import React, { useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import { Button, Grid, MenuItem, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
// import { createSearchParams, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import isEmail from "validator/lib/isEmail"
import SnackBar from '../UI/SnackBar';
import { LOGGEDIN } from '../../Redux/Reducer/userReducer';
import { useDispatch } from 'react-redux';
import { asyncGetUser } from '../../Redux/Actions/userAction';
import { POST } from '../../helpers/http';

const InputForm = (props) => {

    const { type } = props;
    const [snackBar, setSnackbar] = useState({ open: false, message: '', severity: "", horizontal: "top", vertical: "center" })
    // console.log({ type, snackBar })
    const [roles, setRoles] = useState(["Employee", "Facility Manager"])
    const defaultStateValues = {
        email: "",
        password: "",
        ...(type === "signup" && { fullName: "", role: "" })
    }
    // const navigate = useNavigate();
    // const [openAlert, setOpenAlert] = useState(false);
    const [inputs, setInputs] = useState(defaultStateValues);
    const [errors, setErrors] = useState(defaultStateValues)
    const [remoteError, setRemoteError] = useState('');
    const dispatch = useDispatch()

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        px: { sx: 1, sm: 2, md: 3 },
    };

    const validateFields = () => {
        const newErrors = { ...errors }
        if (!inputs.email) newErrors.email = "Email is Required"
        else if (!isEmail(inputs.email)) newErrors.email = "Invalid Email Address"
        else if (inputs.email.length > 128) newErrors.email = "Email length should not exceed 128 characters"

        if (!inputs.password) newErrors.password = "Password is Required"

        if (type === "signup") {
            if (!inputs.fullName) newErrors.fullName = "Full Name is Required"
            else if (inputs.fullName.length < 3) newErrors.fullName = "Full Name must contain atleast 3 characters"
            else if (inputs.fullName.length > 128) newErrors.fullName = "Full Name cannot exceed 128 characters"

            // if (!inputs.companyRef) newErrors.companyRef = "Select Comapny"
            if (inputs.password.length < 8) newErrors.password = "Password length must be atleast 8 characters"
            else if (inputs.password.length > 128) newErrors.password = "Password length cannot exceed 8 characters"

            if (!inputs.role) newErrors.role = "Select User Role"
        }
        if (Object.values(newErrors).join(",").length <= 4) {
            return true
        } else {
            console.log({ newErrors })
            setErrors(newErrors)
            return false
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setRemoteError("")
        if (validateFields()) {
            // console.log({ inputs })
            const api = process.env.REACT_APP_URL + (type === 'signup' ? 'user/signup' : 'user/login');

            POST(api, { ...inputs })
                .then((response) => {
                    const result = response.data
                    console.log({ result })
                    // setRemoteError(!result.success ? result.message : '');
                    if (type === 'signup') {
                        // setOpenAlert(true);

                        // setSnackbar((prevState) => {
                        //     return { ...prevState, open: true, message: 'Account Successfully Created', severity: "success" }
                        // })

                        setInputs({ ...defaultStateValues })
                        setTimeout(() => {
                            props.history.push('/login')
                        }, [1000])
                    }
                    if (type !== "signup") {
                        const result = response?.data
                        console.log(result)
                        localStorage.setItem('token', result.token)
                        dispatch(asyncGetUser())
                        props.history.push('/')
                    }
                })
                .catch((error) => {
                    const errorArray = error?.response?.data
                    // console.log({ error })
                    const status = error?.response?.status
                    if (status === 401) {
                        setRemoteError(errorArray[0]?.message)
                    }
                    else if (status === 500) {
                        // handleSnackbarOpen(errorArray[0]?.message, "error")
                        setSnackbar((prevState) => {
                            return { ...prevState, open: true, message: errorArray[0]?.message, severity: "error" }
                        })
                    } else {
                        // handleSnackbarOpen("Something went wrong!", "error")
                        setSnackbar((prevState) => {
                            return { ...prevState, open: true, message: "Something went wrong!", severity: "error" }
                        })
                    }
                });
        }
    }


    const textProps = { sx: { height: type == 'signup' ? '2vh' : '5vh', fontSize: '1.5vh' } }


    const handleChange = (e) => {
        if (remoteError) setRemoteError("")
        const name = e.target.name
        const value = name === 'role' ? e.target.value.toLowerCase() : e.target.value
        const newInputs = { ...inputs }
        const newErrors = { ...errors }
        newInputs[name] = value
        newErrors[name] = ""
        setInputs(newInputs)
        setErrors(newErrors)
    };


    // const handleClose = () => {
    //     setOpenAlert(false);
    // };

    return (
        <Box
            component="form"
            noValidate
            sx={formStyle}
            onSubmit={handleSubmit}
        >
            {/* <Snackbar
                open={openAlert}
                onClose={handleClose}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity="success">
                    Account Successfully Created
                </Alert>
            </Snackbar> */}
            {type === 'signup' && (
                <TextField
                    name="fullName"
                    placeholder="Full Name"
                    required
                    error={Boolean(errors.fullName)}
                    helperText={errors.fullName}
                    value={inputs.fullName}
                    onChange={handleChange}
                    size='medium'
                    inputProps={textProps}
                    sx={{ pb: '2vh' }}
                />
            )}

            <TextField
                focused
                name="email"
                placeholder="Email ID"
                required
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email}
                value={inputs.email}
                onChange={handleChange}
                size={type == 'signup' ? "medium" : "small"}
                inputProps={textProps}
                sx={{ pb: '2vh' }}
            />
            <TextField
                name="password"
                focused
                placeholder="Password"
                type="password"
                required
                error={Boolean(errors.password)}
                helperText={errors.password}
                value={inputs.password}
                onChange={handleChange}
                size={type == 'signup' ? "medium" : "small"}
                inputProps={textProps}
                sx={{ mb: type === 'signup' ? '2vh' : '5vh' }}
            />

            {type === 'signup' && (

                <TextField
                    select
                    name="role"
                    placeholder="Role"
                    required
                    error={Boolean(errors.role)}
                    helperText={errors.role}
                    value={inputs.role}
                    onChange={handleChange}
                    size='medium'
                    inputProps={textProps}
                    sx={{ pb: '1vh' }}
                >
                    {roles.map((role) => (
                        <MenuItem value={role} key={role}>
                            {role}
                        </MenuItem>
                    ))}
                </TextField>


            )}
            {/* {
                !(type === 'signup') && (
                    <Box display={'flex'} justifyContent={'flex-end'} p={0}  >
                        <Box fontFamily={'Roboto'} fontSize={'11px'} fontWeight={410} >
                            <a href=''>
                                Forgot Password?
                            </a>
                        </Box>
                    </Box>
                )
            } */}
            <Grid container pb={3}>
                <Grid item xs={12} textAlign="left" fontSize={"small"} color={"red"} >
                    {remoteError}
                </Grid>
            </Grid>
            <Box >
                <Box display="flex" justifyContent={"center"} sx={{ pb: '2vh' }}>
                    <Button sx={{ backgroundColor: '#FF6520', borderRadius: '8px', width: "100%", fontFamily: 'Roboto', height: '50px', fontSize: '1.5vh' }} size="small" variant="contained" type="submit">
                        {type === 'signup' ? 'Sign Up' : 'Login'}
                    </Button>

                </Box>
                <Box display='flex' justifyContent={'center'} alignItems='center'>
                    <Typography sx={{ color: '#212121', fontSize: '12px' }}>Powered By</Typography>
                </Box>
                <Box display='flex' justifyContent='center'>
                    <img src="https://nhance.ai/images/nhance%20logo%202.png" width='80px' />
                </Box>
            </Box>

            {/* {snackBar.open && <SnackBar
                message={snackBar.message}
                open={snackBar.open}
                vertical={"top"}
                horizontal={"center"}
                severity={snackBar.severity}
            />} */}
        </Box>
    );
};

export default InputForm;
