import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText, Typography, List, ListItem, ListItemText, Grid, Snackbar, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from "axios"
import { fieldValidation, sensorDeviceValidation } from '../../helpers/sensorDeviceValidation'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { GET, POST, PUT, errorHandler } from '../../helpers/http'
import { useDispatch, useSelector } from 'react-redux'
import { asyncGetAllSensorDevices, asyncGetSelectedSensorDevice } from '../../Redux/Actions/sensorDeviceAction'

const SensorDeviceForm = (props) => {

  const match = useRouteMatch()
  const { id } = match?.params
  const defaultListvalues = () => {
    return { selected: "", data: [] }
  }

  const [sensorID, setSensorID] = useState("")
  const [vendorID, setVendorID] = useState("")
  const [uCode, setUCode] = useState("")
  const [xPos, setXpos] = useState("")
  const [yPos, setYpos] = useState("")
  const [forgeXpos, setForgeXpos] = useState("")
  const [forgeYpos, setForgeYpos] = useState("")
  const [forgeZpos, setForgeZpos] = useState("")
  const [subArea, setSubArea] = useState("")
  const [forgeExternalId, setForgeExternalId] = useState("")

  const [vendorList, setVendorList] = useState(defaultListvalues())
  const [sensorList, setSensorList] = useState(defaultListvalues())
  const [headList, setHeadList] = useState(defaultListvalues())
  const [featureList, setFeatureList] = useState({ selected: [], data: [] })
  const [indiceList, setIndiceList] = useState({ selected: [], data: [] })
  const [locationList, setLocationList] = useState(defaultListvalues())

  const [buildingList, setBuildingList] = useState(defaultListvalues())
  const [floorsList, setFloorList] = useState(defaultListvalues())
  const [zoneList, setZoneList] = useState(defaultListvalues())
  const [subZoneList, setSubZoneList] = useState(defaultListvalues())
  const [areaList, setAreaList] = useState(defaultListvalues())
  const [areaTypeList, setAreaTypeList] = useState(defaultListvalues())
  const [connectionList, setConnectionList] = useState(defaultListvalues())
  const [status, setStatus] = useState(true)

  const [errorObj, setErrorObj] = useState({})
  const [addfield, setAddField] = useState([true])
  // const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error', vertical: 'bottom', horizontal: 'center' })
  const [submitted, setSubmitted] = useState(false)

  const { selectedDevice } = useSelector(state => state?.sensordevices)
  const selectFieldsArr = { "vendor": vendorList, "sensor": sensorList, "head": headList, "connection": connectionList, "location": locationList, "building": buildingList, "floor": floorsList, "zone": zoneList, "subzone": subZoneList, "area": areaList, "areatype": areaTypeList }
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(asyncGetAllSensorDevices())
  }, [])
  useEffect(() => {
    if (id) dispatch(asyncGetSelectedSensorDevice(id))
  }, [id])

  const apiCalls = (type, dependencies) => {

    const getAPI = (api, method) => {
      let url = !dependencies ? `${process.env.REACT_APP_URL}${api}s` : `${process.env.REACT_APP_URL}${api}s?${dependencies}`

      GET(url, "Sensor Devices dependencies")
        .then((response) => {
          const result = response?.data
          method((prevState) => {
            return { ...prevState, data: result }
          })
        })
    }

    if (!dependencies) {
      if (connectionList.data.length === 0) getAPI("connection", setConnectionList)
      if (vendorList.data.length === 0) getAPI("vendor", setVendorList)
      if (headList.data.length === 0) getAPI("head", setHeadList)
      if (sensorList.data.length === 0) getAPI("sensor", setSensorList)
      if (featureList.data.length === 0) getAPI("feature", setFeatureList)
      if (indiceList.data.length === 0) getAPI("indice", setIndiceList)
      if (locationList?.data?.length === 0) getAPI('location', setLocationList)
      if (areaTypeList?.data?.length === 0) getAPI('areatype', setAreaTypeList)
    }

    if (type === 'building') getAPI('building', setBuildingList)
    if (type === 'floor') getAPI('floor', setFloorList)
    if (type === 'zone') getAPI('zone', setZoneList)
    if (type === 'subzone') getAPI('subzone', setSubZoneList)
    if (type === 'area') getAPI('area', setAreaList)

  }

  const reset = (type) => {
    if (type) {
      if (['location'].includes(type)) setBuildingList(defaultListvalues())
      if (['location', 'building'].includes(type)) setFloorList(defaultListvalues())
      if (['location', 'building', 'floor'].includes(type)) setZoneList(defaultListvalues())
      if (['location', 'building', 'floor', 'zone'].includes(type)) setSubZoneList(defaultListvalues())
      if (['location', 'building', 'floor', 'zone', 'subzone'].includes(type)) setAreaList(defaultListvalues())
    } else {
      setSensorID("")
      setVendorID("")
      setUCode("")
      setXpos("")
      setYpos("")
      setForgeXpos("")
      setForgeYpos("")
      setForgeZpos("")
      setVendorList(defaultListvalues())
      setSensorList(defaultListvalues())
      setHeadList(defaultListvalues())
      setFeatureList({ selected: [], data: [] })
      setIndiceList({ selected: [], data: [] })
      setLocationList(defaultListvalues())
      setBuildingList(defaultListvalues())
      setFloorList(defaultListvalues())
      setZoneList(defaultListvalues())
      setSubZoneList(defaultListvalues())
      setAreaList(defaultListvalues())
      setSubArea("")
      setConnectionList(defaultListvalues())
      setAreaTypeList(defaultListvalues())
      setForgeExternalId("")
    }
    apiCalls()

  }
  useEffect(() => {
    apiCalls()
  }, [])

  useEffect(() => {
    if (Object.keys(selectedDevice).length > 0) {

      const prefillSelected = (field, method) => {
        method((prevState) => {
          return { ...prevState, selected: selectedDevice[field]?._id ? selectedDevice[field]?._id : "" }
        })
      }

      setSensorID(selectedDevice?.sensorId ? selectedDevice?.sensorId : "")
      setVendorID(selectedDevice?.vendorId ? selectedDevice?.vendorId : "")
      setUCode(selectedDevice?.uCode ? selectedDevice?.uCode : "")
      setXpos(selectedDevice?.xPos ? selectedDevice?.xPos : "")
      setYpos(selectedDevice?.yPos ? selectedDevice?.yPos : "")
      setForgeXpos(selectedDevice?.forgeCoordinates?.x ? selectedDevice?.forgeCoordinates?.x : "")
      setForgeYpos(selectedDevice?.forgeCoordinates?.y ? selectedDevice?.forgeCoordinates?.y : "")
      setForgeZpos(selectedDevice?.forgeCoordinates?.z ? selectedDevice?.forgeCoordinates?.z : "")
      setSubArea(selectedDevice?.subarea ? selectedDevice?.subarea : "")
      setForgeExternalId(selectedDevice?.forgeExternalId ? selectedDevice?.forgeExternalId : "")
      setStatus(selectedDevice?.status)

      prefillSelected('head', setHeadList)
      prefillSelected('vendor', setVendorList)
      prefillSelected('connection', setConnectionList)
      prefillSelected('sensor', setSensorList)
      prefillSelected('location', setLocationList)
      prefillSelected('areatype', setAreaTypeList)

      apiCalls('building', `location=${selectedDevice?.location?._id}`)
      apiCalls('floor', `building=${selectedDevice?.building?._id}`)
      apiCalls('zone', `floor=${selectedDevice?.floor?._id}`)
      apiCalls('subzone', `zone=${selectedDevice?.zone?._id}`)
      apiCalls('area', `subzone=${selectedDevice?.subzone?._id}`)

      prefillSelected('building', setBuildingList)
      prefillSelected('floor', setFloorList)
      prefillSelected('zone', setZoneList)
      prefillSelected('subzone', setSubZoneList)
      prefillSelected('area', setAreaList)

      let addFieldLength = []
      const featurePrefill = selectedDevice.feature.map(ele => {
        addFieldLength.push(true)
        return ele.featureId._id
      })
      setAddField(addFieldLength)
      const indexPrefill = selectedDevice.feature.map(ele => ele.indexId._id)

      setFeatureList((prevState) => {
        return { selected: featurePrefill, data: prevState.data }
      })
      setIndiceList((prevState) => ({ selected: indexPrefill, data: prevState.data }))
    }

  }, [selectedDevice, id])

  const handleAddFeature = (e) => {
    if ((indiceList.selected.length !== featureList.selected.length || indiceList.selected.length === featureList.selected.length) && indiceList.selected.length !== addfield.length && featureList.selected.length !== addfield.length) {
      const errObj = fieldValidation(addfield, featureList.selected, indiceList.selected)
      setErrorObj(errObj)
    }
    else if (indiceList.selected.length === featureList.selected.length && indiceList.selected.length === addfield.length && featureList.selected.length === addfield.length) {
      setAddField([...addfield, true])
    }
  }

  const handleRemoveFeature = () => {

    if (addfield.length > 1) {
      const updatedField = [...addfield]
      let updatedFeatures = [...featureList.selected], updatedIndices = [...indiceList.selected]
      if (updatedFeatures.length > updatedIndices.length) updatedFeatures.pop()
      else if (updatedFeatures.length < updatedIndices.length) updatedIndices.pop()
      else if (updatedFeatures.length === updatedIndices.length && updatedFeatures.length === updatedField.length && updatedIndices.length === updatedField.length) {
        updatedFeatures.pop()
        updatedIndices.pop()
      }
      updatedField.pop()
      const newFeatures = { selected: [...updatedFeatures], data: [...featureList.data] }
      const newIndices = { selected: [...updatedIndices], data: [...indiceList.data] }
      setAddField(updatedField)
      setFeatureList(newFeatures)
      setIndiceList(newIndices)
      const error = { ...errorObj }
      delete error["feature"]
      delete error["indice"]
      setErrorObj(error)
    }
  }

  const handleTextChange = (e) => {
    const name = e.target.name
    const value = e.target.value.trim() ? e.target.value : e.target.value.trim()

    let sensorData = {
      sensorId: sensorID,
      vendorId: vendorID,
      uCode: uCode,
      sensor: sensorList.selected,
      vendor: vendorList.selected,
      head: headList.selected,
      location: locationList.selected,
      building: buildingList.selected,
      floor: floorsList.selected,
      zone: zoneList.selected,
      subzone: subZoneList.selected,
      area: areaList.selected,
      subarea: subArea,
      connection: connectionList.selected
    }

    if (name === "sensorID") {
      setSensorID(value)
      sensorData["sensorId"] = value
    }
    else if (name === "uCode") {
      setUCode(value)
      sensorData["uCode"] = value
    }
    else if (name === "vendorID") {
      setVendorID(value)
      sensorData["vendorId"] = value
    }
    else if (name === "subArea") {
      setSubArea(value)
      sensorData["subarea"] = value
    }
    else if (name === "xPos") setXpos(value)
    else if (name === "yPos") setYpos(value)
    else if (name === "forgeXpos") setForgeXpos(value)
    else if (name === "forgeYpos") setForgeYpos(value)
    else if (name === "forgeZpos") setForgeZpos(value)
    else if (name === "forgeExternalId") setForgeExternalId(value)

    if (submitted) {
      const list = featureList.selected.length >= indiceList.selected.length ? featureList.selected : indiceList.selected
      const feature = list.map((ele, i) => ({ featureId: featureList.selected[i], indexId: indiceList.selected[i] }))
      const updatedError = sensorDeviceValidation({ ...sensorData, feature }, addfield)
      // console.log({ updatedError })
      setErrorObj(updatedError)
    }
  }

  const handleSelectChange = (e, index) => {
    const name = e.target.name
    const value = e.target.value

    let sensorData = {
      sensorId: sensorID,
      vendorId: vendorID,
      uCode: uCode,
      sensor: sensorList.selected,
      vendor: vendorList.selected,
      head: headList.selected,
      location: locationList.selected,
      building: buildingList.selected,
      floor: floorsList.selected,
      zone: zoneList.selected,
      subzone: subZoneList.selected,
      area: areaList.selected,
      subarea: subArea,
      connection: connectionList.selected,
      status: status
    }

    let selectedFeatures = [...featureList.selected], selectedIndices = [...indiceList.selected]   //For Error Messages
    if (name === "buildingIDSelect") {
      const data = { selected: value, data: [...buildingList.data] }
      sensorData["building"] = value
      setBuildingList(data)
      reset('building')
      apiCalls('floor', `building=${value}`)
    }
    else if (name === "locationIDSelect") {
      const data = { selected: value, data: [...locationList.data] }
      sensorData["location"] = value
      setLocationList(data)
      reset('location')
      apiCalls('building', `location=${value}`)

    } else if (name === "floorIDSelect") {
      const data = { selected: value, data: [...floorsList.data] }
      sensorData["floor"] = value
      setFloorList(data)
      reset('floor')
      apiCalls('zone', `floor=${value}`)
    } else if (name === "zoneIDSelect") {
      const data = { selected: value, data: [...zoneList.data] }
      sensorData["zone"] = value
      setZoneList(data)
      reset('zone')
      apiCalls('subzone', `zone=${value}`)
    } else if (name === "subzoneIDSelect") {
      const data = { selected: value, data: [...subZoneList.data] }
      sensorData["subzone"] = value
      setSubZoneList(data)
      reset('subzone')
      apiCalls('area', `subzone=${value}`)
    } else if (name === "vendorIDSelect") {
      const data = { selected: value, data: [...vendorList.data] }
      sensorData["vendor"] = value
      setVendorList(data)
    } else if (name === "sensorIDSelect") {
      const data = { selected: value, data: [...sensorList.data] }
      sensorData["sensor"] = value
      setSensorList(data)
    } else if (name === "connectionIDSelect") {
      const data = { selected: value, data: [...connectionList.data] }
      sensorData["connection"] = value
      setConnectionList(data)
    } else if (name === "areaIDSelect") {
      const data = { selected: value, data: [...areaList.data] }
      sensorData["area"] = value
      setAreaList(data)
    } else if (name === "headIDSelect") {
      const data = { selected: value, data: [...headList.data] }
      sensorData["head"] = value
      setHeadList(data)
    } else if (name === "areatypeIDSelect") {
      const data = { selected: value, data: [...areaTypeList.data] }
      sensorData["areatype"] = value
      setAreaTypeList(data)
    } else if (name === "statusIDSelect") {
      sensorData["status"] = value
      setStatus(value)
    }
    else if (name === "indiceIDSelect" || name === "featureIDSelect") {
      if (name === "featureIDSelect" && !featureList.selected.includes(value)) {
        let newFeatures = [...featureList.selected]
        newFeatures.splice(index, 1, value)  // Add and edit feature
        const data = { selected: newFeatures, data: [...featureList.data] }
        selectedFeatures = [...data.selected]
        selectedIndices = [...indiceList.selected]
        setFeatureList(data)
      } else if (name === "indiceIDSelect" && !indiceList.selected.includes(value)) {
        let newIndices = [...indiceList.selected]
        newIndices.splice(index, 1, value) //Add and Edit indice
        const data = { selected: newIndices, data: [...indiceList.data] }
        selectedIndices = [...data.selected]
        selectedFeatures = [...featureList.selected]
        setIndiceList(data)
      } else if (name === "featureIDSelect" && featureList.selected.includes(value)) {
        selectedFeatures = [...featureList.selected]
        selectedIndices = [...indiceList.selected]
        errorHandler('Feature already selected, Select Different Feature')
      } else if (name === "indiceIDSelect" && indiceList.selected.includes(value)) {
        const noneIndex = indiceList.data.find(ele => ele.name.toLowerCase() === 'none')
        if (noneIndex?._id === value) {
          let newIndices = [...indiceList.selected]
          newIndices.splice(index, 1, value) //Add and Edit indice
          const data = { selected: newIndices, data: [...indiceList.data] }
          selectedIndices = [...data.selected]
          setIndiceList(data)
        } else {
          selectedIndices = [...indiceList.selected]
          errorHandler('Index already selected, Select Different Index')
        }
        selectedFeatures = [...featureList.selected]
      }
      const errObj = fieldValidation(addfield, selectedFeatures, selectedIndices)
      setErrorObj(errObj)
    }

    if (submitted) {
      const list = selectedFeatures.length >= selectedIndices.length ? selectedFeatures : selectedIndices
      const feature = list.map((ele, i) => ({ featureId: selectedFeatures[i], indexId: selectedIndices[i] }))
      const updatedError = sensorDeviceValidation({ ...sensorData, feature }, addfield)
      setErrorObj(updatedError)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    const list = featureList.selected.length >= indiceList.selected.length ? featureList.selected : indiceList.selected
    const feature = list.map((ele, i) => ({ featureId: featureList.selected[i], indexId: indiceList.selected[i] }))

    let addSensor = {
      sensorId: sensorID.trim(),
      vendorId: vendorID.trim(),
      uCode: uCode.trim(),
      sensor: sensorList.selected,
      vendor: vendorList.selected,
      head: headList.selected,
      feature,
      location: locationList.selected,
      building: buildingList.selected,
      floor: floorsList.selected,
      zone: zoneList.selected,
      subzone: subZoneList.selected,
      area: areaList.selected,
      subarea: subArea.trim(),
      connection: connectionList.selected,
      areatype: areaTypeList.selected,
      forgeExternalId: forgeExternalId.trim(),
      status
    }

    let error = sensorDeviceValidation(addSensor, addfield)
    // console.log({ error })
    setErrorObj(error)

    if (Object.keys(error).length === 0) {

      const data = { ...addSensor, forgeCoordinates: {} }

      if (xPos) data['xPos'] = xPos.trim()
      if (yPos) data['yPos'] = yPos.trim()
      if (forgeXpos) data['forgeCoordinates']['x'] = forgeXpos.trim()
      if (forgeYpos) data['forgeCoordinates']['y'] = forgeYpos.trim()
      if (forgeZpos) data['forgeCoordinates']['z'] = forgeZpos.trim()
      // console.log({ data })
      if (!id) {
        //Adding a new sensor
        POST(`${process.env.REACT_APP_URL}sensordevice/`, data, "Sensor Device")
          .then(response => {
            setSensorID("")
            setVendorID("")
            setUCode("")
            setXpos("")
            setYpos("")
            setForgeXpos("")
            setForgeYpos("")
            setForgeZpos("")
            setSubArea("")
            // reset()
            // props.history.push('/sensordevices')
          })
          .catch(error => {
            const errorArray = error?.response?.data
            const status = error?.response?.status
            console.log({ errorArray })
            if (status === 409 || status === 400) {
              //conflict error or validation errors
              const obj = {}
              errorArray.forEach(error => {
                if (error.path[0] === 'forgeCoordinates') {
                  if (error.path[1] === 'x') obj['forgeX'] = error.message
                  if (error.path[1] === 'y') obj['forgeY'] = error.message
                  if (error.path[1] === 'z') obj['forgeZ'] = error.message
                } else {
                  obj[error.path[0]] = error.message
                }
              })
              setErrorObj(obj)
            }

          })
      } else {

        PUT(`${process.env.REACT_APP_URL}sensordevice/${id}`, data, "Sensor Device")
          .then(response => {
            reset()
            props.history.push('/sensordevices')
          })
          .catch(error => {
            const errorArray = error?.response?.data
            const status = error?.response?.status
            if (status === 409 || status === 400) {
              //conflict error or validation errors
              const obj = {}
              errorArray.forEach(error => {
                if (error.path[0] === 'forgeCoordinates') {
                  if (error.path[1] === 'x') obj['forgeX'] = error.message
                  if (error.path[1] === 'y') obj['forgeY'] = error.message
                  if (error.path[1] === 'z') obj['forgeZ'] = error.message
                } else {
                  obj[error.path[0]] = error.message
                }
              })
              setErrorObj(obj)
            }
          })
      }
    }
  }

  const selectFields = (item) => {

    let label = `Select ${item[0].toUpperCase()}${item.slice(1)}`, id = `${item}Label`, name = `${item}IDSelect`
    return (<FormControl fullWidth sx={{ maxWidth: "500px", m: 1 }} key={item}>
      <InputLabel id={id} error={!!errorObj[item]}>{label}*</InputLabel>
      <Select
        labelId={id}
        fullWidth
        error={!!errorObj[item]}
        id="demo-simple-select"
        value={selectFieldsArr[item].selected}
        label={label}
        name={name}
        onChange={handleSelectChange}
      >
        {selectFieldsArr[item].data.map((ele) => {
          return (
            <MenuItem value={ele._id} key={ele._id}>{ele.name}</MenuItem>
          )
        })}
      </Select>
      <FormHelperText error>{errorObj[item] ? errorObj[item] : null}</FormHelperText>
    </FormControl>)
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '500px' },
        textAlign: "center",
        p: 2,
        borderRadius: 1,
        backgroundColor: "white"
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      display='flex'
      flexWrap={'wrap'}
    >
      <Box sx={{ mb: 3 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">{id ? "Edit this" : "Add a"} Sensor Device</Typography>
        </Box>
        <FormControl >
          <TextField
            required
            fullWidth={true}
            error={!!errorObj.sensorId}
            id="outlined-required"
            label="Sensor ID"
            value={sensorID}
            name="sensorID"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["sensorId"] ? errorObj["sensorId"] : null}</FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            required
            error={!!errorObj.vendorId}
            fullWidth={true}
            id="outlined-required"
            label="Vendor ID"
            value={vendorID}
            name="vendorID"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["vendorId"] ? errorObj["vendorId"] : null}</FormHelperText>
        </FormControl>

        <FormControl >
          <TextField
            required
            fullWidth={true}
            error={!!errorObj.uCode}
            id="outlined-required"
            label="U Code"
            value={uCode}
            name="uCode"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["uCode"] ? errorObj["uCode"] : null}</FormHelperText>

        </FormControl>

        {
          Object.keys(selectFieldsArr).map(item => {
            return selectFields(item)
          })
        }
        <FormControl>
          <TextField
            required
            fullWidth={true}
            id="sub area"
            error={!!errorObj.subarea}
            label="Sub Area"
            value={subArea}
            name="subArea"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["subarea"] ? errorObj["subarea"] : null}</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ maxWidth: "500px", m: 1 }} >
          <InputLabel id="statusLabel" error={!!(errorObj?.status ? errorObj["status"] : null)} >Select Status *</InputLabel>
          <Select
            labelId="statusLabel"
            id="demo-simple-select"
            error={!!(errorObj?.status ? errorObj["status"] : null)}
            value={status}
            label="Select Status"
            name="statusIDSelect"
            onChange={(e) => handleSelectChange(e)}
            style={{ color: status ? 'green' : 'red', fontWeight: "bold" }}
          >
            {[true, false].map((item, i) => {
              return (
                <MenuItem value={item} key={i} >{item ? "Active" : "Inactive"}</MenuItem>
              )
            })}
          </Select>
          <FormHelperText error>{errorObj?.status ? errorObj["status"] : null}</FormHelperText>
        </FormControl>
        <FormControl >
          <TextField
            // required
            fullWidth={true}
            id="xpos"
            error={!!errorObj.xPos}
            label="X Position"
            value={xPos}
            name="xPos"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["xPos"] ? errorObj["xPos"] : null}</FormHelperText>
        </FormControl>
        <FormControl >
          <TextField
            // required
            fullWidth={true}
            id="yPos"
            error={!!errorObj.yPos}
            label="Y Position"
            value={yPos}
            name="yPos"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["yPos"] ? errorObj["yPos"] : null}</FormHelperText>
        </FormControl>
        <FormControl >
          <TextField
            // required
            fullWidth={true}
            id="forge-x"
            error={!!errorObj.forgeX}
            label="Forge-X Position"
            value={forgeXpos}
            name="forgeXpos"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["forgeX"] ? errorObj["forgeX"] : null}</FormHelperText>
        </FormControl>
        <FormControl >
          <TextField
            // required
            fullWidth={true}
            id="forge-y"
            label="Forge-Y Position"
            error={!!errorObj.forgeY}
            value={forgeYpos}
            name="forgeYpos"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["forgeY"] ? errorObj["forgeY"] : null}</FormHelperText>
        </FormControl>
        <FormControl >
          <TextField
            // required
            fullWidth={true}
            id="forge-z"
            error={!!errorObj.forgeZ}
            label="Forge-Z Position"
            value={forgeZpos}
            name="forgeZpos"
            onChange={handleTextChange}
          />
          <FormHelperText error>{errorObj["forgeZ"] ? errorObj["forgeZ"] : null}</FormHelperText>
        </FormControl>

        <Box sx={{ width: "100%" }}>
          <Button
            align="center"
            variant="filled"
            // type="submit"
            sx={{
              m: 4,
              backgroundColor: "#2e7d32",
              color: "white",
              ':hover': { bgcolor: '#2e7d32	', color: 'white', fontWeight: "bold" },
              width: "200px"
            }}
            onClick={handleAddFeature}
          >Add More Feature/Index</Button>
          <Button
            align="center"
            variant="filled"
            // type="submit"
            sx={{
              m: 4,
              backgroundColor: "#e53935",
              color: "white",
              ':hover': { bgcolor: '#e53935', color: 'white', fontWeight: "bold" },
              width: "200px"
            }}
            onClick={handleRemoveFeature}
          >Remove Feature/Index</Button>
        </Box>
        {
          addfield.map((ele, i) => {
            return (
              featureList.data && (
                <Grid container key={ele + i} spacing={2} >
                  <Grid item xs={6} sx={{ display: "flex", justifyContent: "right" }} mb={3} >
                    <FormControl sx={{ width: "80%", maxWidth: "500px", mr: 3 }}>
                      <InputLabel id="featureLabel" error={!!(errorObj?.feature ? errorObj["feature"][i] : null)} >Select Feature *</InputLabel>
                      <Select
                        labelId="featureLabel"
                        id="demo-simple-select"
                        error={!!(errorObj?.feature ? errorObj["feature"][i] : null)}
                        value={featureList.selected[i] ? featureList.selected[i] : ''}
                        label="Select Feature"
                        name="featureIDSelect"
                        onChange={(e) => handleSelectChange(e, i)}
                      >
                        {
                          featureList.data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map((item) => {
                            return (
                              <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                            )
                          })
                        }
                      </Select>
                      <FormHelperText error>{errorObj?.feature ? errorObj["feature"][i] : null}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", justifyContent: "left" }} mb={3}>
                    <FormControl sx={{ width: "80%", maxWidth: "500px", ml: 3 }}>
                      <InputLabel id="indiceLabel" error={!!(errorObj?.indice ? errorObj["indice"][i] : false)} >Select Indices *</InputLabel>
                      <Select
                        labelId="indiceLabel"
                        id="demo-simple-select"
                        error={!!(errorObj?.indice ? errorObj["indice"][i] : false)}
                        value={indiceList.selected[i] ? indiceList.selected[i] : ''}
                        label="Select Indice"
                        name="indiceIDSelect"
                        onChange={(e) => handleSelectChange(e, i)}
                      >
                        {indiceList.data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map((item) => {
                          return (
                            <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText error>{errorObj?.indice ? errorObj["indice"][i] : null}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              )
            )
          })}
      </Box >
      <Box sx={{ display: 'flex', justifyContent: "center", width: "100%" }}>
        <Button
          align="center"
          variant="filled"
          type="submit"
          sx={{
            mr: 4,
            backgroundColor: "#1976D2",
            color: "white",
            ':hover': { bgcolor: '#FF4500	', color: 'white' },
            width: "200px"
          }}
        >{!id ? 'Save' : 'Update'}</Button>
        <Button
          align="center"
          variant="filled"
          sx={{
            backgroundColor: "#333333",
            color: "white",
            ':hover': { bgcolor: '#333333	', color: 'white' },
            width: "200px"
          }}
          onClick={() => { props.history.push('/sensordevices') }}
        >Cancel</Button>
      </Box>
    </Box >
  )
}

export default SensorDeviceForm