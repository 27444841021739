import { DELETE_MASTER_DATA, GET_ALL_DATA, GET_SELECTED_MASTER_DATA } from "../Actions/masterListAction"

const initialState = {
    location: { data: [], selected: {} },
    building: { data: [], selected: {} },
    floor: { data: [], selected: {} },
    wing: { data: [], selected: {} },
    zone: { data: [], selected: {} },
    subzone: { data: [], selected: {} },
    area: { data: [], selected: {} },
    areatype: { data: [], selected: {} },
    vendor: { data: [], selected: {} },
    sensor: { data: [], selected: {} },
    head: { data: [], selected: {} },
    feature: { data: [], selected: {} },
    indice: { data: [], selected: {} },
    connection: { data: [], selected: {} },
    currency: { data: [], selected: {} }
}

const masterListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_DATA: {
            return {
                ...state, [action?.payload?.listType]: { data: action?.payload?.data, selected: { ...state[action?.payload?.listType]["selected"] } }
            }
        }
        case DELETE_MASTER_DATA: {
            const data = state[action.payload.listType]?.data.filter(ele => ele._id !== action.payload?.data)
            return {
                ...state,
                [action.payload.listType]: { data }
            }
        }
        case GET_SELECTED_MASTER_DATA: {
            return { ...state, [action.payload.listType]: { ...state[action.payload.listType], selected: action.payload?.data } }
        }
        case "LOGOUT": {
            return initialState
        }
        case "REFRESH": {
            return initialState
        }
        default: return { ...state }
    }
}

export default masterListReducer