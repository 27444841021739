import axios from "axios"
import { GET, PUT } from "../../helpers/http"

export const UPDATE_TENANT_LIST = "UPDATE_TENANT_LIST"
export const UPDATE_TENANT = "UPDATE_TENANT"

const getAllTenants = (data) => {
    return { type: UPDATE_TENANT_LIST, payload: data }
}

const updateTenant = (data) => {
    return { type: UPDATE_TENANT, payload: data }
}

export const asyncGetAllTenants = () => {
    return (dispatch) => {
        GET(`${process.env.REACT_APP_URL}tenants`)
            .then((response) => {
                const result = response?.data
                dispatch(getAllTenants(result))
            })
            .catch((error) => {
                if (error?.response?.status === 401) dispatch({ type: "LOGOUT" })
                console.error(error)
            })
    }
}


export const asyncGetTenantToken = (selectedTenant, redirect) => {
    GET(`${process.env.REACT_APP_URL}tenant/token/${selectedTenant}`)
        .then((response) => {
            const result = response?.data
            localStorage.setItem('tenantId', selectedTenant)
            localStorage.setItem('tenantToken', result?.tenantToken)
            redirect({ status: response.status })
        })
        .catch((error) => {
            console.error(error)
        })
}


export const asyncUpdateTenant = (id, data, redirect) => {
    return (dispatch) => {
        PUT(`${process.env.REACT_APP_URL}tenant/${id}`, data, "Tenant")
            .then((response) => {
                const result = response?.data
                dispatch(updateTenant(result))
                redirect()
            })
            .catch((error) => {
                if (error?.response?.status === 401) dispatch({ type: "LOGOUT" })
                console.error(error)

            })
    }
}

