import React, { useEffect, useState } from 'react'
import Main from './components/Main'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline, createTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { asyncGetUser } from './Redux/Actions/userAction'
import HomePage from './components/HomePage'
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


const App = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const appIsLoaded = useSelector(state => state?.user?.isLoaded)
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem('token'))
  const location = useLocation()
  const pathname = location?.pathname?.split('/')
  console.log({ appIsLoaded, isLogged })

  useEffect(() => {
    setIsLogged(!!localStorage.getItem('token'))
  }, [appIsLoaded])

  useEffect(() => {
    if (isLogged) {
      // localStorage.removeItem('tenantToken')
      // localStorage.removeItem('tenantId')
      dispatch(asyncGetUser())
      // history.push('/')
      // dispatch({ type: "REFRESH" })
    }
    else if (!isLogged && (pathname[1] === 'login' || pathname[1] === '')) history.push('/login')
    else if (!isLogged && pathname[1] === 'signup') history.push('/signup')
    else history.push('/login')
  }, [isLogged])

  const handleLogin = () => {
    setIsLogged(false)
  }

  const theme = createTheme()

  return (
    <Box width={"100%"}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!isLogged ? <Main /> : <HomePage handleLogin={handleLogin} />}
      </ThemeProvider >
    </Box>
  )
}

export default App