import { GET_ALL_QRCODES, UPDATE_GENERATED_QRCODES } from "../Actions/qrcodesAction"

const initialState = {
    data: []
}

const qrCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_QRCODES: {
            console.log(action.payload)
            return { ...state, data: [...action.payload] }
        }
        case UPDATE_GENERATED_QRCODES: {
            console.log(action.payload)
            return { ...state, data: [...state.data, ...action.payload] }
        }
        case "LOGOUT": {
            return initialState
        }
        case "REFRESH": {
            return initialState
        }
        default: return { ...state }
    }
}

export default qrCodeReducer