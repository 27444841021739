import React, { useEffect } from 'react'
import Header from './UI/Header'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import MiniDrawer from './UI/Drawer'
import { withRouter } from 'react-router-dom'
import PrivateRoute from './PrivateRoute/PrivateRoute'
import SensorDeviceForm from './Forms/SensorDevicesForm'
import { Route } from 'react-router-dom'
import { asyncGetAllTenants } from '../Redux/Actions/tenantAction'
import { SELECTED_TENANT } from '../Redux/Reducer/tenantReducer'
import Tenant from './Main/Tenant'
import { ListAlt, QrCode2, Sensors, SettingsOutlined } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom'
import MasterList from './Pages/MasterList'
import SensorDevicePage from './Pages/SensorDevicePage'
import QrCodePage from './Pages/QrCodePage'
import ConfigPage from './Pages/ConfigPage'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";


const HomePage = (props) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const tenantList = useSelector((state) => state.tenant?.tenantsList)
    const tenantId = localStorage.getItem('tenantId')
    console.log({ tenantId })
    useEffect(() => {
        dispatch(asyncGetAllTenants())
        if (location.pathname === '/') {
            localStorage.removeItem('tenantId')
            localStorage.removeItem('tenantToken')
        }
    }, [])

    useEffect(() => {
        if (tenantId) {
            const tenant = tenantList.find(ele => ele._id === tenantId)
            dispatch({ type: SELECTED_TENANT, payload: tenant || {} })
        }
        else {
            history.push('/')
        }
    }, [tenantId, tenantList])

    const drawerList = localStorage.getItem('tenantToken') ? ([
        { index: 0, title: 'Sensor Devices', icon: <Sensors />, path: '/sensordevices', value: [] },
        { index: 1, title: 'Master List', icon: <ListAlt />, path: '/list/location', value: ['location', 'building', 'floor', 'wing', 'zone', 'subzone', 'area', 'areatype', 'vendor', 'sensor', 'head', 'feature', 'Indice', 'connection'] },
        { index: 2, title: 'QR Codes', icon: <QrCode2 />, path: '/qrcodes', value: [] },
        { index: 3, title: 'Configuration', icon: <SettingsOutlined />, path: '/configuration/twinapi', value: ['twin_api', 'asset_management', 'fault_report', 'meeting_room', 'desk_booking', 'feedback'] },
    ]) : []

    return (
        <Box>
            <Header />
            <Box display={"flex"} flexGrow={1} sx={{ height: "92vh" }} >
                <MiniDrawer drawerList={drawerList}
                />
                {tenantList.length > 0 && <Box width={"100vw"}>
                    <Route path="/" component={Tenant} exact />
                    <PrivateRoute path="/sensordevices" component={SensorDevicePage} exact />
                    <PrivateRoute path="/list/:listType" component={MasterList} exact />
                    <PrivateRoute path="/sensordevice/add" component={SensorDeviceForm} exact />
                    <PrivateRoute path="/add/:listType" component={MasterList} />
                    <PrivateRoute path="/sensordevice/edit/:id" component={SensorDeviceForm} exact />
                    <PrivateRoute path="/edit/:listType/:id" component={MasterList} exact />
                    <PrivateRoute path="/configuration/:service" component={ConfigPage} exact />
                    <PrivateRoute path="/qrcodes" component={QrCodePage} exact />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover={false}
                        theme="colored"
                    />
                </Box>}
            </Box>
        </Box >
    )
}

export default withRouter(HomePage)
