import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, Button } from '@mui/material'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { asyncUpdateTenant } from '../../Redux/Actions/tenantAction'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { POST } from '../../helpers/http'

const AddTenantForm = (props) => {
    const { handleSnackbarOpen, forceLogout, listType = 'tenant', id, handleCancel, type } = props

    const [formType, setFormType] = useState(0)
    const [name, setName] = useState('')
    const [tenantId, setTenantId] = useState('')
    // const [services, setServices] = useState([])
    const [selectedServices, setSelectedServices] = useState({})
    const [formErrors, setFormErrors] = useState({})

    const dispatch = useDispatch()
    const history = useHistory()

    const errors = {}
    const selectedTenant = useSelector((state) => state.tenant?.selectedTenant)

    useEffect(() => {
        if (id) {
            setName(selectedTenant?.name)
            setTenantId(selectedTenant?.tenantId)
        }
    }, [id])

    // useEffect(() => {
    //     if (id) {
    //         axios.get(`${process.env.REACT_APP_URL}${listType}/${id}`, {
    //             headers: {
    //                 Authorization: localStorage.getItem('token'),
    //                 "x-tenant-id": localStorage.getItem('tenantId'),
    //                 'auth-tenant': localStorage.getItem('tenantToken')
    //             }
    //         })
    //             .then((response) => {
    //                 const result = response?.data
    //                 setName(result?.name)
    //             })
    //             .catch((error) => {
    //                 const errorArray = error?.response?.data
    //                 const status = error?.response?.status
    //                 if (status === 401) {
    //                     forceLogout()
    //                     handleSnackbarOpen(errorArray[0]?.message, "error")
    //                 } else if (status === 500) {
    //                     handleSnackbarOpen(errorArray[0]?.message, "error")
    //                 } else {
    //                     handleSnackbarOpen("Something went wrong!", "error")
    //                 }
    //             })
    //     }
    // }, [id, listType])

    // useEffect(() => {
    //     if (formType === 1) {
    //         axios.get(`${process.env.REACT_APP_URL}services`, {
    //             headers: {
    //                 Authorization: localStorage.getItem('token')
    //             }
    //         })
    //             .then((response) => {
    //                 const result = response?.data

    //                 // const servicesObj = {}

    //                 // result.forEach(ele => {
    //                 //     servicesObj[ele.name] = false
    //                 // })

    //                 // setServices(result)
    //                 // setSelectedServices(servicesObj)
    //             })
    //             .catch((error) => {
    //                 const errorArray = error?.response?.data
    //                 const status = error?.response?.status
    //                 if (status === 401) {
    //                     forceLogout()
    //                     handleSnackbarOpen(errorArray[0]?.message, "error")
    //                 } else if (status === 500) {
    //                     handleSnackbarOpen(errorArray[0]?.message, "error")
    //                 } else {
    //                     handleSnackbarOpen("Something went wrong!", "error")
    //                 }
    //             })
    //     }
    // }, [formType])

    const handleChange = (e) => {
        const name = e.target.name, value = e.target.value.trim() ? e.target.value : e.target.value.trim()
        if (name === 'name') setName(value)
        if (name === 'tenantId') setTenantId(value)
    }

    const cancelClick = () => {
        handleCancel()
    }

    const clearForm = () => {
        setName("")
    }

    const runValidations = () => {
        if (name.trim().length === 0) {
            errors.name = "Name cannot be empty"
        } else if (name.trim().length < 2) {
            errors.name = "Name should be mininum 2 characters"
        }
    }

    // const handleNext = () => {
    //     runValidations()

    //     if (Object.keys(errors).length === 0) {
    //         setFormErrors({})

    //         const formObj = {
    //             name: name.trim()
    //         }

    //         // if (id) {
    //         //     axios.put(`${process.env.REACT_APP_URL}${listType}/${id}`, formObj, {
    //         //         headers: {
    //         //             Authorization: localStorage.getItem("token"),
    //         //             "x-tenant-id": localStorage.getItem('tenantId'),
    //         //             'auth-tenant': localStorage.getItem('tenantToken')
    //         //         }
    //         //     })
    //         //         .then((response) => {
    //         //             clearForm()
    //         //             handleSnackbarOpen(`${listType.charAt(0).toUpperCase()}${listType.slice(1)} Updated!`, "success")
    //         //             props.history.push(`/list/${listType}`)
    //         //         })
    //         //         .catch((error) => {
    //         //             const errorArray = error?.response?.data
    //         //             const status = error?.response?.status
    //         //             if (status === 409 || status === 400) {
    //         //                 //conflict error & validation error
    //         //                 setFormErrors({ name: errorArray[0]?.message })
    //         //             } else if (status === 401) {
    //         //                 forceLogout()
    //         //                 handleSnackbarOpen(errorArray[0]?.message, "error")
    //         //             } else if (status === 500) {
    //         //                 handleSnackbarOpen(errorArray[0]?.message, "error")
    //         //             } else {
    //         //                 handleSnackbarOpen("Something went wrong!", "error")
    //         //             }
    //         //         })
    //         // } else {
    //         //     axios.post(`${process.env.REACT_APP_URL}${listType}`, formObj, {
    //         //         headers: {
    //         //             Authorization: localStorage.getItem("token"),
    //         //             "x-tenant-id": localStorage.getItem('tenantId'),
    //         //             'auth-tenant': localStorage.getItem('tenantToken')
    //         //         }
    //         //     })
    //         //         .then((response) => {
    //         //             const result = response.data
    //         //             clearForm()
    //         //             handleSnackbarOpen(`${listType.charAt(0).toUpperCase()}${listType.slice(1)} Added!`, "success")
    //         //             props.history.push(listType === 'tenant' ? "/" : `/list/${listType}`)
    //         //         })
    //         //         .catch((error) => {
    //         //             const errorArray = error?.response?.data
    //         //             const status = error?.response?.status
    //         //             if (status === 409 || status === 400) {
    //         //                 //conflict error & validation error
    //         //                 setFormErrors({ name: errorArray[0]?.message })
    //         //             } else if (status === 401) {
    //         //                 forceLogout()
    //         //                 handleSnackbarOpen(errorArray[0]?.message, "error")
    //         //             } else if (status === 500) {
    //         //                 handleSnackbarOpen(errorArray[0]?.message, "error")
    //         //             } else {
    //         //                 handleSnackbarOpen("Something went wrong!", "error")
    //         //             }
    //         //         })
    //         // }

    //         setFormType(1)

    //     } else {
    //         setFormErrors(errors)
    //     }
    // }

    const handleSubmit = () => {

        runValidations()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            setFormErrors({})

            const formObj = {
                name: name.trim(),
                tenantId: tenantId.trim()
            }

            const redirect = () => {
                handleCancel()
            }

            if (id) {
                dispatch(asyncUpdateTenant(selectedTenant?._id, formObj, redirect))
            } else {
                POST(`${process.env.REACT_APP_URL}tenant`, formObj, "Tenant")
                    .then((response) => {
                        const result = response.data
                        clearForm()
                        props.history.push('/')
                    })
                    .catch((error) => {
                        const errorArray = error?.response?.data
                        const status = error?.response?.status
                        if (status === 409 || status === 400) {
                            //conflict error & validation error
                            setFormErrors({ name: errorArray[0]?.message })
                        }
                    })
            }
        }

    }

    return (
        <Box sx={{ width: "100%", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F1F3F4" }}>
            {formType === 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "60%", width: "45%", backgroundColor: "white", boxShadow: "2px 2px 4px gray" }} border="solid 1px gray" borderRadius={2}>
                    <Typography
                        variant="h6"
                        m={6}
                        fontWeight="bold"
                    >{type[0].toUpperCase() + type.slice(1)} Tenant</Typography>
                    <TextField
                        value={name}
                        name="name"
                        onChange={handleChange}
                        label={`Tenant Name *`}
                        sx={{ width: "50%", mb: 5 }}
                        helperText={formErrors.name}
                        error={!!formErrors.name}
                        autoFocus
                    />

                    <TextField
                        value={tenantId}
                        name="tenantId"
                        onChange={handleChange}
                        label={`Tenant ID`}
                        sx={{ width: "50%", mb: 5 }}
                        // helperText={formErrors.tenantId}
                        // error={!!formErrors.name}
                        autoFocus
                    />
                    <Box sx={{ display: 'flex', justifyContent: "space-evenly", width: "30%", mb: 5 }}>
                        <Button
                            variant="filled"
                            onClick={handleSubmit}
                            sx={{
                                mr: 4,
                                backgroundColor: "#1976D2",
                                color: "white",
                                ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                                width: "200px"
                            }}
                        >Submit</Button>
                        <Button
                            variant="filled"
                            sx={{
                                backgroundColor: "#333333",
                                color: "white",
                                ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                                width: "200px"
                            }}
                            onClick={cancelClick}
                        >Cancel</Button>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "60%", width: "60%", backgroundColor: "white" }} border="solid 1px #1976D2" borderRadius={2}>
                    <Typography
                        variant="h6"
                        mb={6}
                    >Select services</Typography>

                    <Box sx={{ display: 'flex', justifyContent: "space-evenly", width: "30%" }}>
                        <Button
                            variant="filled"
                            onClick={handleSubmit}
                            sx={{
                                mr: 4,
                                backgroundColor: "#1976D2",
                                color: "white",
                                ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                                width: "200px"
                            }}
                        >Confirm</Button>
                        <Button
                            variant="filled"
                            sx={{
                                backgroundColor: "#333333",
                                color: "white",
                                ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                                width: "200px"
                            }}
                            onClick={cancelClick}
                        >Cancel</Button>
                    </Box>
                </Box>
            )
            }
        </Box >
    )
}

export default withRouter(AddTenantForm)