import axios from "axios"
import { GET_USER } from "../Reducer/userReducer"

const getUser = (data) => {
    return { type: GET_USER, payload: data }
}

export const asyncGetUser = () => {
    return (dispatch) => {
        axios.get(`${process.env.REACT_APP_URL}user`, { headers: { Authorization: localStorage.getItem('token') } })
            .then((response) => {
                const result = response?.data
                dispatch(getUser(result))
            })
            .catch((error) => {
                console.log(error)
            })
    }
}