import { UPDATE_TENANT, UPDATE_TENANT_LIST } from "../Actions/tenantAction"

export const REMOVE_SELECTED_TENANT = "REMOVE_SELECTED_TENANT"
export const SELECTED_TENANT = "SELECTED_TENANT"

const initialState = {
    selectedTenant: {},
    tenantsList: []
}

const tenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_TENANT_LIST: {
            return { ...state, tenantsList: action.payload }
        }
        case SELECTED_TENANT: {
            return { ...state, selectedTenant: action.payload }
        }
        case UPDATE_TENANT: {

        }
        case "LOGOUT": {
            return initialState
        }
        case "REFRESH": {
            return { ...state, selectedTenant: {} }
        }
        default: return { ...state }
    }
}

export default tenantReducer