import { GET, POST } from "../../helpers/http"

export const GET_ALL_QRCODES = "GET_ALL_QRCODES"
export const UPDATE_GENERATED_QRCODES = "UPDATE_GENERATED_QRCODES"

const getAllQrcodes = (data) => {
    return { type: GET_ALL_QRCODES, payload: data }
}

const updatedQrcodes = (data) => {
    return { type: UPDATE_GENERATED_QRCODES, payload: data }
}

export const asyncGetAllQrcodes = () => {
    return (dispatch) => {
        GET(`${process.env.REACT_APP_URL}qrcodes`)
            .then((response) => {
                dispatch(getAllQrcodes(response.data))
            })
            .catch((error) => {
                const status = error?.response?.status
                const errorArray = error?.response?.data
                console.log(error)
            })
    }
}

export const asyncGenerateQrCodes = () => {
    return (dispatch) => {
        POST(`${process.env.REACT_APP_URL}qrcodes`, {}, "QrCodes")
            .then((response) => {
                console.log(response)
                dispatch(updatedQrcodes(response.data.flat(2)))
            })
            .catch((error) => {
                const status = error?.response?.status
                const errorArray = error?.response?.data
                console.log(error)
            })
    }
}