import { GET } from "../../helpers/http"

export const GET_ALL_SENSOR_DEVICES = "GET_ALL_SENSOR_DEVICES"
export const GET_SELECTED_SENSOR_DEVICE = "GET_SELECTED_SENSOR_DEVICE"

const getAllSensorDevices = (data) => {
    return { type: GET_ALL_SENSOR_DEVICES, payload: data }
}


const getSelectedSensorDevices = (data) => {
    return { type: GET_SELECTED_SENSOR_DEVICE, payload: data }
}

export const asyncGetAllSensorDevices = () => {
    return (dispatch) => {
        GET(`${process.env.REACT_APP_URL}sensordevices`)
            .then((response) => {
                dispatch(getAllSensorDevices(response.data))
            })
            .catch((error) => {
                console.error(error.message)
            })
    }
}

export const asyncGetSelectedSensorDevice = (id) => {
    return (dispatch) => {
        GET(`${process.env.REACT_APP_URL}sensordevice/${id}`)
            .then((response) => {
                dispatch(getSelectedSensorDevices(response.data))
            })
            .catch((error) => {
                console.error(error.message)
            })
    }
}