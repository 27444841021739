import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Divider, Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const BasicCard = (props) => {
   const { content } = props;
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))
   const height = window.innerHeight
   return (
      <Box sx={{ margin: "100px", p: 1 }}>
         <Card elevation={1} sx={{ borderRadius: '0px', width: "100%", backgroundColor: "#F5F5F5", height: "100%", margin: "0px" }}>
            <Grid p={3} pt={8} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: '#FF6520', height: "32%" }}>
               <Box display='flex' justifyContent={'center'} >
                  <Typography fontFamily={'Roboto'} fontSize='5vh' sx={{ color: "white" }}>Nhance Admin</Typography>
                  {/* <img width='50%' src="https://nhance.ai/images/nhance%20logo%202.png" style={{ borderColor: 'black' }} /> */}
               </Box>
               <Box pt={4} display='flex' justifyContent={'center'} >
                  <Typography fontWeight={200} textAlign={'center'} maxWidth={"80%"} fontSize='1.5vh' fontFamily={'Roboto'} color={'white'} >
                     Creating the future of workplaces, data centres, and healthcare spaces.
                  </Typography>
               </Box>
            </Grid>
            <Divider />
            <Grid item >
               <CardContent sx={{ backgroundColor: '#f5f5f5', height: "100%", margin: "10px" }} >{content}</CardContent>
            </Grid>
            <Divider />
            <Grid item sx={{ backgroundColor: '#4b4b4b' }} color={'white'} fontSize={12}>
               <Box display={'flex'} width={'100%'} justifyContent={'center'} height="100%" alignItems="center"  >
                  <Box p={0.5} >
                     <Typography fontSize={'1.5vh'}> Have a query, write to us at <a color='white'>needhelp@nhance.ai</a></Typography>
                  </Box>
               </Box>
            </Grid>
         </Card>
      </Box>
   );
};

export default BasicCard;