import React, { useState, useEffect } from 'react'
import { Box, Typography, Divider, Button, FormControl, InputLabel, MenuItem, Select, Stack, IconButton } from '@mui/material'
import axios from 'axios'
import AddTenantForm from '../Forms/AddTenantForm'
import { asyncGetAllTenants, asyncGetTenantToken } from '../../Redux/Actions/tenantAction'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Heading from '../UI/Heading'
import { SELECTED_TENANT } from '../../Redux/Reducer/tenantReducer'
import SnackBar from '../UI/SnackBar'
import Datatable1 from './DataTable1'
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material'


const Tenant = (props) => {
    // const { handleSnackbarOpen, forceLogout } = props
    const [openForm, setOpenForm] = useState({ type: '', bool: false })
    const [selectedTenant, setSelectedTenant] = useState("")
    // const []
    // const [tenantList, setTenantList] = useState([])
    const [snackBar, setSnackbar] = useState({ open: false, message: '', severity: "", horizontal: "top", vertical: "center" })

    // const token = localStorage.getItem("token")
    const dispatch = useDispatch()
    const history = useHistory()

    const tenantList = useSelector((state) => state.tenant?.tenantsList)
    const tenantId = localStorage.getItem('tenantId')

    useEffect(() => {
        if (tenantId) {
            const tenant = tenantList.find(ele => ele._id === tenantId)
            dispatch({ type: SELECTED_TENANT, payload: tenant })
        }
    }, [])


    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_URL}tenants`, {
    //         headers: {
    //             Authorization: localStorage.getItem('token')
    //         }
    //     })
    //         .then((response) => {
    //             setTenantList(response?.data)
    //         })
    //         .catch((error) => {
    //             const errorArray = error?.response?.data
    //             const status = error?.response?.status
    //             if (status === 401) {
    //                 // forceLogout()
    //                 // handleSnackbarOpen(errorArray[0]?.message, "error")
    //                 setSnackbar((prevState) => {
    //                     return { ...prevState, open: true, message: errorArray[0]?.message, severity: "error" }
    //                 })
    //             } else if (status === 500) {
    //                 // handleSnackbarOpen(errorArray[0]?.message, "error")
    //                 setSnackbar((prevState) => {
    //                     return { ...prevState, open: true, message: errorArray[0]?.message, severity: "error" }
    //                 })
    //             } else {
    //                 // handleSnackbarOpen("Something went wrong!", "error")
    //                 setSnackbar((prevState) => {
    //                     return { ...prevState, open: true, message: "Something went wrong!", severity: "error" }
    //                 })
    //             }
    //         })

    //     dispatch(asyncGetAllTenants())

    // }, [])


    // useEffect(() => {
    //     if (selectedTenant) {
    //         axios.get(`${process.env.REACT_APP_URL}tenant/token/${selectedTenant}`, {
    //             headers: {
    //                 Authorization: localStorage.getItem('token')
    //             }
    //         })
    //             .then((response) => {
    //                 const result = response?.data
    //                 localStorage.setItem('tenantId', selectedTenant)
    //                 const tenant = tenantList.find(ele => ele._id === selectedTenant)
    //                 localStorage.setItem('tenantName', tenant?.name)
    //                 localStorage.setItem('tenantToken', result?.tenantToken)
    //                 history.push('/sensordevices')
    //             })
    //             .catch((error) => {
    //                 const errorArray = error?.response?.data
    //                 const status = error?.response?.status
    //                 if (status === 401) {
    //                     // forceLogout()

    //                     // handleSnackbarOpen(errorArray[0]?.message, "error")
    //                     history.push('/login')
    //                     setSnackbar((prevState) => {
    //                         return { ...prevState, open: true, message: errorArray[0]?.message, severity: "error" }
    //                     })
    //                 } else if (status === 500) {
    //                     // handleSnackbarOpen(errorArray[0]?.message, "error")
    //                     setSnackbar((prevState) => {
    //                         return { ...prevState, open: true, message: errorArray[0]?.message, severity: "error" }
    //                     })
    //                 } else {
    //                     // handleSnackbarOpen("Something went wrong!", "error")
    //                     setSnackbar((prevState) => {
    //                         return { ...prevState, open: true, message: "Something went wrong!", severity: "error" }
    //                     })
    //                 }
    //             })
    //     }
    // }, [selectedTenant])

    const handleCancel = () => {
        dispatch(asyncGetAllTenants())
        setOpenForm({ type: '', bool: false })
    }

    const handleOpenForm = (type, id) => {
        setOpenForm({ type, bool: true })
        setSelectedTenant(id ? id : "")
        const tenant = tenantList.find(ele => ele._id === id)
        dispatch({ type: SELECTED_TENANT, payload: tenant })
    }

    const handleTenantSelect = (id) => {

        setSelectedTenant(id)
        const tenant = tenantList.find(ele => ele._id === id)
        dispatch({ type: SELECTED_TENANT, payload: tenant })

        const redirect = (response) => {
            if (response.status === 200) {
                history.push('/sensordevices')
            }
            else if (response.status === 401) {
                history.push('/login')
                setSnackbar((prevState) => {
                    return { ...prevState, open: true, message: response.error[0]?.message, severity: "error" }
                })
            } else if (response.status === 500) {
                setSnackbar((prevState) => {
                    return { ...prevState, open: true, message: response.error[0]?.message, severity: "error" }
                })
            } else {
                setSnackbar((prevState) => {
                    return { ...prevState, open: true, message: "Something went wrong!", severity: "error" }
                })
            }

        }
        asyncGetTenantToken(id, redirect)
    }

    const columns = [
        {
            field: 'SrNo',
            headerName: 'Sr. No.',
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Tenant Name',
            width: 300,
            valueGetter: (params) => params.row?.name ? params.row?.name : 'NA'
        },
        {
            field: 'tenantId',
            headerName: 'Tenant ID',
            width: 300,
            editable: false,
            valueGetter: (params) => params.row?.tenantId ? params.row?.tenantId : 'NA'
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    // <IconButton
                    //     size="small"
                    //     onClick={() => handleOpenForm('edit', cellValues.id)}
                    // >
                    //     <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                    //     <Typography color={'#1976D2'} variant={'caption'}>
                    //         &nbsp;Edit
                    //     </Typography>
                    // </IconButton>
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleOpenForm('edit', cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={() => handleTenantSelect(cellValues.id)}
                        >
                            <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;View
                            </Typography>
                        </IconButton>
                    </Stack>
                )
            }
        }
    ]

    const rows = tenantList.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })

    return (
        <Box >
            {/* <Box sx={{ backgroundColor: "", height: "64px", display: "flex", alignItems: "center" }}            >
                <Typography
                    sx={{ ml: 3, color: "black", fontSize: "150%", fontWeight: "bold" }}
                >Tenant Page</Typography>
            </Box>
            <Divider /> */}
            <Heading title={"Tenant Page"} />
            {!openForm.bool && <Box
                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", p: 2, backgroundColor: "#f1f1f1" }}
            >
                <FormControl
                    size="small"
                    sx={{ width: "220px", mr: 3, }}
                >
                    <InputLabel id="demo-simple-select-label">Select Tenant</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedTenant}
                        label="Select Tenant"
                        onChange={(e) => handleTenantSelect(e.target.value)}
                    >
                        {tenantList.map(tenant => {
                            return <MenuItem value={tenant._id} key={tenant._id}>{tenant.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Button
                    variant="filled"
                    sx={{
                        maxWidth: "220px",
                        // alignSelf: 'end',
                        // mr: 5,
                        // mb: 2,
                        // mt: 1,
                        width: "150px",
                        backgroundColor: "#DC4D28",
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    onClick={() => { handleOpenForm('add') }}
                >
                    Add Tenant
                </Button>


            </Box>}
            {openForm?.bool && <AddTenantForm
                handleCancel={handleCancel}
                type={openForm.type}
                id={selectedTenant}
            // handleSnackbarOpen={handleSnackbarOpen}
            // forceLogout={forceLogout}
            />}
            {/* {snackBar.open && <SnackBar
                message={snackBar.message}
                open={snackBar.open}
                vertical={"top"}
                horizontal={"center"}
                severity={snackBar.severity}
            />} */}
            {!openForm.bool && <Datatable1 columns={columns} rows={rows} pageSize={50} rowsPerPage={50} checkBox={false} />}

        </Box>
    )
}

export default Tenant