import { GET_CONFIGURATION } from "../Actions/configAction"

const initialState = {
    'twinapi': {},
    'assetmanagement': {},
    'faultreport': {},
    'meetingroom': {},
    'deskbooking': {},
    'feedback': {}
}

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONFIGURATION: {
            return { ...state, [action.payload.service]: action.payload.data }
        }
        case "LOGOUT": {
            return initialState
        }
        case "REFRESH": {
            return initialState
        }
        default: return state
    }
}

export default configReducer