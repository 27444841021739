import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { POST, PUT } from '../../helpers/http'
import { asyncGetSelectedMasterData } from '../../Redux/Actions/masterListAction'

const CurrencyForm = (props) => {
    const { listType, id } = props

    const [currencyData, setCurrencyData] = useState({ name: '', acronym: '', symbol: '' })
    const [formErrors, setFormErrors] = useState({})
    const dispatch = useDispatch()

    const selectedCurrency = useSelector(state => state?.masterList?.currency?.selected)

    useEffect(() => {
        if (id) dispatch(asyncGetSelectedMasterData({ listType, id }))
    }, [id, listType])

    useEffect(() => {
        if (id) setCurrencyData({ name: selectedCurrency?.name, acronym: selectedCurrency?.acronym, symbol: selectedCurrency?.symbol })
        else setCurrencyData({ name: '', acronym: '', symbol: '' })
    }, [selectedCurrency])


    const cancelClick = () => {
        props?.history?.push(`/list/${listType}`)
    }

    const runValidations = (name) => {
        const errors = {}
        for (let key in errors) {
            if (currencyData[key].trim === "") { }
        }
        if (currencyData?.name.length === 0) {
            errors.name = "Name cannot be empty"
        } else if (currencyData?.name.length < 2) {
            errors.name = "Name should be mininum 2 characters"
        }
        if (currencyData?.acronym.length === 0) errors.acronym = "Acronym cannot be empty"
        if (currencyData?.symbol.length === 0) errors.symbol = "Currency symbol cannot be empty"
        return errors
    }

    const handleChange = (e) => {
        const name = e.target.name, value = e.target.value.trim() ? e.target.value : e.target.value.trim()
        setCurrencyData({ ...currencyData, [name]: value })
        setFormErrors((value) => ({ ...value, [name]: "" }))
    }


    const handleSubmit = () => {
        const errors = runValidations()
        setFormErrors(errors)
        if (Object.keys(errors).length === 0) {
            setFormErrors({})
            if (id) {
                PUT(`${process.env.REACT_APP_URL}currency/${id}`, currencyData, 'Currency')
            } else {
                POST(`${process.env.REACT_APP_URL}currency`, currencyData, `Currency`)
                    .then(() => {
                        setCurrencyData({ name: '', acronym: '', symbol: '' })
                    })
            }
        } else {
            setFormErrors(errors)
        }
    }


    return (
        <Box sx={{ width: "100%", height: "70vh", display: "flex", justifyContent: "center" }} mt={20}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "60%", width: "40%", backgroundColor: "white", boxShadow: "2px 2px 4px gray", }} border="solid 1px #f1f1f1" borderRadius={2}>
                <Typography
                    variant="h5"
                >{id ? "Edit this" : "Add a New"} Currency</Typography>
                <FormControl>

                    <TextField
                        value={currencyData?.name}
                        onChange={handleChange}
                        name='name'
                        label={`Enter ${listType} Name`}
                        fullWidth sx={{ width: "22vw" }}
                        helperText={formErrors?.name}
                        error={!!formErrors?.name}
                        autoFocus
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        value={currencyData?.acronym}
                        onChange={handleChange}
                        name="acronym"
                        label={`Enter ${listType} Acronym`}
                        fullWidth sx={{ width: "22vw" }}
                        helperText={formErrors?.acronym}
                        error={!!formErrors?.acronym}
                        autoFocus
                    />
                </FormControl>
                <FormControl>

                    <TextField
                        value={currencyData?.symbol}
                        onChange={handleChange}
                        name="symbol"
                        label={`Enter ${listType} Symbol`}
                        fullWidth sx={{ width: "22vw" }}
                        helperText={formErrors?.symbol}
                        error={!!formErrors?.symbol}
                        autoFocus
                    />
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: "space-evenly", width: "30%" }}>
                    <Button
                        variant="filled"
                        onClick={handleSubmit}
                        sx={{
                            mr: 4,
                            backgroundColor: "#1976D2",
                            color: "white",
                            ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                    >{listType === 'tenant' ? "Next" : "Confirm"}</Button>
                    <Button
                        variant="filled"
                        sx={{
                            backgroundColor: "#333333",
                            color: "white",
                            ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                        onClick={cancelClick}
                    >Cancel</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default withRouter(CurrencyForm)