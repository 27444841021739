import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const Heading = (props) => {

    return (
        <>
            <Box
                sx={{
                    height: "60px",
                    display: "flex",
                    backgroundColor: "white",
                    alignItems: "center",
                    marginTop: "5px",
                    width: "100%"
                }}

            >
                {/* {tenantName && <Typography
                    sx={{ ml: 3, color: "black", fontSize: "160%", fontWeight: "bold" }}
                >{tenantName} - </Typography>} */}
                <Typography
                    sx={{ ml: 3, color: "black", fontSize: "160%", fontWeight: "bold" }}
                >{props.title}</Typography>
            </Box>
            <Divider sx={{ color: "black" }} />
        </>
    )
}

export default Heading