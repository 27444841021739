import React, { useEffect } from 'react'
import Heading from '../UI/Heading'
import DataTable1 from '../Main/DataTable1'
import { Stack, Typography, IconButton, Button, Box } from '@mui/material'
import { DeleteOutline, EditOutlined, Circle } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { asyncGetAllSensorDevices, asyncGetSelectedSensorDevice } from '../../Redux/Actions/sensorDeviceAction'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import swal from 'sweetalert'

const SensorDevicePage = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const sensorList = useSelector(state => state?.sensordevices?.data)

    const handleDeleteItem = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.delete(`${process.env.REACT_APP_URL}sensordevice/${id}`, {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            "x-tenant-id": localStorage.getItem('tenantId'),
                            'auth-tenant': localStorage.getItem('tenantToken')
                        }
                    })
                        .then((response) => {
                            const result = response.data
                            // removeItem(result?._id)
                            // handleSnackbarOpen(`${type.charAt(0).toUpperCase()}${type.slice(1)} Deleted!`, "success")
                        })
                        .catch((error) => {
                            const status = error?.response?.status
                            const errorArray = error?.response?.data
                            console.log(error)
                            // if (status === 401) {
                            //     forceLogout()
                            //     handleSnackbarOpen(errorArray[0]?.message, "error")
                            // } else if (status === 500) {
                            //     handleSnackbarOpen(errorArray[0]?.message, "error")
                            // } else {
                            //     handleSnackbarOpen("Something went wrong!", "error")
                            // }
                        })
                }
            })
    }

    const handleEditItem = (id) => {
        dispatch(asyncGetSelectedSensorDevice(id))
        props?.history?.push(`/sensordevice/edit/${id}`)
    }

    useEffect(() => {
        if (localStorage.getItem("tenantToken") && localStorage.getItem('tenantId')) {
            dispatch(asyncGetAllSensorDevices())
        }
    }, [])

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            width: 60,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Circle sx={{ color: cellValues?.value ? 'green' : 'red', height: "20px" }} />
                )
            }
        },
        {
            field: 'sensorId',
            headerName: 'Sensor ID',
            width: 200,
            editable: false,
        },
        {
            field: 'vendorId',
            headerName: 'Vendor ID',
            width: 200,

        },
        {
            field: 'uCode',
            headerName: 'UCode',
            width: 110,
            editable: false,
        },

        {
            field: 'vendor',
            headerName: 'Vendor',
            width: 100,
            valueGetter: (params) => params.row?.vendor?.name ? params.row?.vendor?.name + ' - ' + params.row.vendor._id : 'NA'
        },
        {
            field: 'sensor',
            headerName: 'Sensor',
            width: 150,
            editable: false,
            valueGetter: (params) => params.row?.sensor?.name ? params.row?.sensor?.name : 'NA'
        },
        {
            field: 'head',
            headerName: 'Head',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.head?.name ? params.row?.head?.name : 'NA'
        },
        {
            field: 'feature',
            headerName: 'Feature',
            width: 200,
            editable: false,
            valueGetter: (params) => params.row?.feature ? params.row?.feature?.map(ele => `${ele?.featureId?.name}`) : 'NA'
        },

        {
            field: 'index',
            headerName: 'Index',
            width: 200,
            editable: false,
            valueGetter: (params) => params.row?.feature ? params.row?.feature.map(ele => `${ele?.indexId?.name}`) : 'NA'
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.location?.name ? params.row?.location?.name : 'NA'
        },
        {
            field: 'building',
            headerName: 'Building',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.building?.name ? params.row?.building?.name : 'NA'
        },
        {
            field: 'floor',
            headerName: 'Floor',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.floor?.name ? params.row?.floor?.name : 'NA'
        },
        {
            field: 'zone',
            headerName: 'Zone',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.zone?.name ? params.row?.zone?.name : 'NA'

        },
        {
            field: 'subzone',
            headerName: 'Sub Zone',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.subzone?.name ? params.row?.subzone?.name : 'NA'
        },
        {
            field: 'area',
            headerName: 'Area',
            width: 350,
            editable: false,
            valueGetter: (params) => params.row?.area?.name ? `${params.row?.area?.name} - ${params.row.area._id}` : 'NA'
        },
        {
            field: 'areatype',
            headerName: 'Area Type',
            width: 100,
            editable: false,
            valueGetter: (params) => params.row?.areatype?.name ? `${params.row?.areatype?.name}` : 'NA'
        },
        {
            field: 'subarea',
            headerName: 'Sub Area',
            width: 110,
            editable: false,
        },
        {
            field: 'connection',
            headerName: 'Connection',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.connection?.name ? params.row?.connection?.name : 'NA'

        },
        // {
        //   field: 'createdAt',
        //   headerName: 'Created At',
        //   width: 150,
        //   editable: false,
        //   valueGetter: (params) => params.row?.createdAt ? moment(params.row?.createdAt).format('lll') : 'NA'
        // },
        // {
        //   field: 'updatedAt',
        //   headerName: 'Updated At',
        //   width: 150,
        //   editable: false,
        //   valueGetter: (params) => params.row?.updatedAt ? moment(params.row?.updatedAt).format('lll') : 'NA'
        // },
        // {
        //   field: 'xpos',
        //   headerName: 'X-Pos',
        //   width: 80,
        //   editable: false,
        //   valueGetter: (params) => params.row?.xPos ? params.row?.xPos : '-'
        // },
        // {
        //   field: 'ypos',
        //   headerName: 'Y-Pos',
        //   width: 80,
        //   editable: false,
        //   valueGetter: (params) => params.row?.yPos ? params.row?.yPos : '-'
        // },
        {
            field: 'forgeCoordinates.x',
            headerName: 'Forge X',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.forgeCoordinates?.x ? params.row?.forgeCoordinates?.x : 'NA'

        }, {
            field: 'forgeCoordinates.y',
            headerName: 'Forge Y',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.forgeCoordinates?.y ? params.row?.forgeCoordinates?.y : 'NA'

        }, {
            field: 'forgeCoordinates.z',
            headerName: 'Forge Z',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.forgeCoordinates?.z ? params.row?.forgeCoordinates?.z : 'NA'

        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleEditItem(cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={() => handleDeleteItem(cellValues.id)}
                        >
                            <DeleteOutline fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Delete
                            </Typography>
                        </IconButton>
                    </Stack>
                )
            }
        }

    ]

    const rows = sensorList.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })



    return (
        <Box>
            <Heading title={"Sensor Devices"} />
            <Box display="flex" justifyContent="flex-end" height={"60px"} alignItems={"center"} bgcolor={"#f1f1f1"}>
                <Button
                    variant="filled"
                    sx={{
                        maxWidth: "220px",
                        width: "200px",
                        // alignSelf: 'end',
                        mr: 3,
                        // mb: 2,
                        // mt: 1,
                        backgroundColor: '#DC4D28',
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    onClick={() => { history?.push(`/sensordevice/add`) }}
                >
                    Add Sensor Device
                </Button>
            </Box>
            <DataTable1 rows={rows} columns={columns} pageSize={50} rowsPerPage={50} />
        </Box>
    )
}

export default SensorDevicePage