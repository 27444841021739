import React, { useEffect } from 'react'
import TabComp from '../Main/Tabs'
import { Box } from '@mui/material'
import Config from '../Main/Configuration'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { asyncGetConfiguration } from '../../Redux/Actions/configAction'
import { useDispatch, useSelector } from 'react-redux'

const ConfigPage = (props) => {

    const tabsList = ['twin_api', 'asset_management', 'fault_report', 'meeting_room', 'desk_booking', 'feedback']
    const match = useRouteMatch()
    const dispatch = useDispatch()
    const { service } = match.params
    const { name: tenant, tenantId } = useSelector(state => state.tenant?.selectedTenant)


    const baseArr = [
        {
            title: 'twinapi',
            url: process.env.REACT_APP_TWIN_API_BASE_URL
        },
        {
            title: 'assetmanagement',
            url: process.env.REACT_APP_ASSET_BASE_URL
        },
        {
            title: 'meetingroom',
            url: process.env.REACT_APP_MEETING_ROOM_BASE_URL
        },
        {
            title: 'faultreport',
            url: process.env.REACT_APP_FAULT_REPORT_BASE_URL
        },
        {
            title: 'feedback',
            url: process.env.REACT_APP_FEEDBACK_BASE_URL
        },
        {
            title: 'deskbooking',
            url: process.env.REACT_APP_DESK_BOOKING_BASE_URL
        }
    ]

    let baseUrl = baseArr.find(ele => ele.title === service)

    useEffect(() => {
        if (tenantId) dispatch(asyncGetConfiguration(baseUrl.url, tenantId, service))
    }, [baseUrl, tenantId])

    return (
        <Box >
            <TabComp tabsList={tabsList} />
            <Config service={service} tenantId={tenantId} />
        </Box>
    )
}

export default ConfigPage