const initialState = {
    isLoaded: false,
    user: {}
}

export const LOGGEDIN = "LOGGEDIN"
export const GET_USER = "GET_USER"

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER: {
            return { ...state, user: action.payload, isLoaded: true }
        }
        case "LOGOUT": {
            return initialState
        }
        default: return { ...state }
    }
}

export default userReducer