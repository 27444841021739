import axios from "axios"
import { toast } from "react-toastify"

export const GET_CONFIGURATION = "GET_CONFIGURATION"

const errorHandler = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    })
}

const getConfiguration = (data) => {
    return { type: GET_CONFIGURATION, payload: data }
}

export const asyncGetConfiguration = (baseUrl, tenantId, service) => {
    return (dispatch) => {
        axios.get(`${baseUrl}v1/saas/tenant/config`, {
            headers: {
                "x-tenant-id": tenantId,
                "x-api-key": process.env.REACT_APP_SAASCONFIG_API_KEY
            }
        })
            .then((response) => {
                dispatch(getConfiguration({ service, data: response.data }))
            })
            .catch((error) => {
                errorHandler(error.message)
                console.log(error)
            })
    }
}