
import { Route } from 'react-router-dom';

import { LoginForm, SignUpForm } from '../Forms/Form';


export const PublicRoutes = (props) => {

    return (
        <>
            <Route path="/signup" render={(props) => {
                return <SignUpForm {...props} />
            }} exact />
            <Route path="/login" render={(props) => {
                return <LoginForm {...props} />
            }} exact />
        </>
    )
}
