import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import tenantReducer from '../Reducer/tenantReducer'
import userReducer from '../Reducer/userReducer'
import sensorDeviceReducer from '../Reducer/sensorDeviceReducer'
import qrCodeReducer from '../Reducer/qrcodesReducer'
import masterListReducer from '../Reducer/masterListReducer'
import configReducer from '../Reducer/configReducer'

const store = createStore(combineReducers({
    user: userReducer,
    tenant: tenantReducer,
    sensordevices: sensorDeviceReducer,
    qrcode: qrCodeReducer,
    masterList: masterListReducer,
    configuration: configReducer
}), applyMiddleware(thunk))

export default store