
import { Redirect, Route } from "react-router-dom"

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (localStorage.getItem("tenantToken")) ? (
                    <Component {...rest} {...props} />
                ) : (
                    < Redirect
                        to={
                            { pathname: "/" }
                        }
                    />
                )
            }
            }
        />
    )
}

export default PrivateRoute