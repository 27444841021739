import React, { useEffect } from 'react'
import TabComp from "../Main/Tabs"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { asyncDeleteMasterData, asyncGetAllMasterData } from '../../Redux/Actions/masterListAction'
import moment from 'moment'
import Datatable1 from '../Main/DataTable1'
import IndividualForm from '../Main/IndividualPage'
import AddForm from '../Forms/MasterListForm'
import { Stack, Typography, IconButton, Button, Box } from '@mui/material'
import { DeleteOutline, EditOutlined, GetAppRounded, QrCode2 } from '@mui/icons-material'
import axios from 'axios'
import swal from 'sweetalert'
import CurrencyForm from '../Forms/CurrencyForm'
import MasterListForm from '../Forms/MasterListForm'

const MasterList = (props) => {

    const tabs = ['location', 'currency', 'building', 'floor', 'wing', 'zone', 'subzone', 'area', 'areatype', 'vendor', 'sensor', 'head', 'feature', 'indice', 'connection']
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const match = useRouteMatch()
    const { listType, id } = match.params

    const openAddForm = location.pathname.split('/').includes('add')
    const openEditForm = location.pathname.split('/').includes('edit')
    const { data } = useSelector(state => state?.masterList[listType])

    useEffect(() => {
        if (listType) {
            dispatch(asyncGetAllMasterData(listType))
        }
    }, [listType])


    const handleDeleteItem = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    dispatch(asyncDeleteMasterData({ listType, id }))
                    // axios.delete(`${process.env.REACT_APP_URL}${listType}/${id}`, {
                    //     headers: {
                    //         Authorization: localStorage.getItem('token'),
                    //         "x-tenant-id": localStorage.getItem('tenantId'),
                    //         'auth-tenant': localStorage.getItem('tenantToken')
                    //     }
                    // })
                    //     .then((response) => {
                    //         const result = response.data
                    //         // removeItem(result?._id)
                    //         // handleSnackbarOpen(`${type.charAt(0).toUpperCase()}${type.slice(1)} Deleted!`, "success")
                    //     })
                    //     .catch((error) => {
                    //         const status = error?.response?.status
                    //         const errorArray = error?.response?.data
                    //         console.log(error)
                    //         // if (status === 401) {
                    //         //     forceLogout()
                    //         //     handleSnackbarOpen(errorArray[0]?.message, "error")
                    //         // } else if (status === 500) {
                    //         //     handleSnackbarOpen(errorArray[0]?.message, "error")
                    //         // } else {
                    //         //     handleSnackbarOpen("Something went wrong!", "error")
                    //         // }
                    //     })
                }
            })
    }

    const handleEditItem = (id) => {
        if (listType) {
            props?.history?.push(`/edit/${listType}/${id}`)
        }
    }

    const columns = listType !== 'currency' ? [
        {
            field: 'SrNo',
            headerName: 'Sr. No.',
            width: 100,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 250
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            valueGetter: (params) => {
                if (['zone', 'subzone', 'area'].includes(listType)) {
                    return params.row?.name ? `${params.row.name} - ${params.row?.floor?.name}` : 'NA'
                } else if (listType === 'building') {
                    let str = params.row?.name ? `${params.row.name} - ` : 'NA - '
                    str += params.row?.currency ? `( ${params.row.currency.acronym} - ${params.row.currency.name} )` : 'NA'
                    return str
                } else if (listType === 'floor') {
                    return params.row?.name ? `${params.row.name} - ${params.row?.building?.name}` : 'NA'
                } else {
                    return params.row.name
                }
            }
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 250,
            valueGetter: (params) => `${params?.row?.createdAt ? moment(params?.row?.createdAt).format('lll') : "NA"}`
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 250,
            valueGetter: (params) => `${params?.row?.updatedAt ? moment(params?.row?.updatedAt).format('lll') : "NA"}`
        },
        {
            field: 'Actions',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleEditItem(cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={() => handleDeleteItem(cellValues.id)}
                        >
                            <DeleteOutline fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Delete
                            </Typography>
                        </IconButton>
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ] : [
        {
            field: 'SrNo',
            headerName: 'Sr. No.',
            width: 100,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 250
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            valueGetter: (params) => {
                if (['zone', 'subzone', 'area'].includes(listType)) {
                    return params.row?.name ? `${params.row.name} - ${params.row?.floor?.name}` : 'NA'
                }
                else if (listType === 'floor') {
                    return params.row?.name ? `${params.row.name} - ${params.row?.building?.name}` : 'NA'
                } else {
                    return params.row.name
                }
            }
        },
        {
            field: 'acronym',
            headerName: 'Acronym',
            width: 200,
            valueGetter: (params) => `${params?.row?.acronym || "-"}`
        },
        {
            field: 'symbol',
            headerName: 'Symbol',
            width: 200,
            valueGetter: (params) => `${params?.row?.symbol || "-"}`
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 250,
            valueGetter: (params) => `${params?.row?.createdAt ? moment(params?.row?.createdAt).format('lll') : "NA"}`
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 250,
            valueGetter: (params) => `${params?.row?.updatedAt ? moment(params?.row?.updatedAt).format('lll') : "NA"}`
        },
        {
            field: 'Actions',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleEditItem(cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={() => handleDeleteItem(cellValues.id)}
                        >
                            <DeleteOutline fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Delete
                            </Typography>
                        </IconButton>
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },


    ]


    const rows = data?.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })

    return (
        <Box>
            <TabComp tabsList={tabs} />
            <Box sx={{ backgroundColor: "#F1F1F1", border: "1px solid #f1f1f1", height: "85vh" }}>
                {(!openAddForm && !openEditForm) ? <>
                    <Box display="flex" justifyContent="flex-end" height={"60px"} alignItems={"center"} bgcolor={"#f1f1f1"}>
                        <Button
                            variant="filled"
                            sx={{
                                maxWidth: "220px",
                                width: "200px",
                                // alignSelf: 'end',
                                mr: 3,
                                // mb: 2,
                                // mt: 1,
                                backgroundColor: '#DC4D28',
                                color: 'white',
                                ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                            }}
                            onClick={() => { history?.push(`/add/${listType}`) }}
                        >
                            Add {listType}
                        </Button>
                    </Box>
                    <Datatable1 rows={rows} columns={columns} pageSize={20} rowsPerPage={20} />
                </>
                    :
                    listType === "currency" ? <CurrencyForm
                        // handleSnackbarOpen={handleSnackbarOpen}
                        // forceLogout={forceLogout}
                        listType={listType}
                        id={id}
                    /> : <MasterListForm
                        // handleSnackbarOpen={handleSnackbarOpen}
                        // forceLogout={forceLogout}
                        listType={listType}
                        id={id}
                    />
                }
            </Box>
        </Box>
    )
}

export default MasterList