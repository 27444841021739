import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GET, POST, PUT } from '../../helpers/http'

const MasterListForm = (props) => {
    const { listType, id, handleCancel } = props
    const [name, setName] = useState('')
    const [formErrors, setFormErrors] = useState({})

    const defaultListvalues = () => {
        return { selected: "", data: [] }
    }

    const [locationList, setLocationList] = useState(defaultListvalues())
    const [buildingList, setBuildingList] = useState(defaultListvalues())
    const [floorsList, setFloorList] = useState(defaultListvalues())
    const [zoneList, setZoneList] = useState(defaultListvalues())
    const [subZoneList, setSubZoneList] = useState(defaultListvalues())
    const [wingList, setWingList] = useState(defaultListvalues())
    const [currencyList, setCurrencyList] = useState(defaultListvalues())

    const tenant = useSelector(state => state?.tenant?.selectedTenant?.name)
    const tenantName = tenant?.replace(" ", "").toLowerCase()

    const tabs = {
        adrianse: ['location', 'building', 'floor', 'zone', 'subzone', 'area'],
        nhance: ['location', 'building', 'floor', 'zone', 'subzone', 'area'],
        opentext: ['location', 'building', 'floor', 'wing', 'area']
    }

    const tabList = Object.keys(tabs).includes(tenantName) ? tabs[tenantName] : tabs['nhance']

    const index = tabList.indexOf(listType)

    const apiCalls = (type, dependencies) => {
        const getData = (api, method) => {
            const masterType = api === 'currency' ? 'currencies' : `${api}s`
            console.log({ masterType })
            let url = !dependencies ? `${process.env.REACT_APP_URL}${masterType}` : `${process.env.REACT_APP_URL}${masterType}?${dependencies}`

            GET(url)
                .then((response) => {
                    const result = response?.data
                    method((prevState) => {
                        return { ...prevState, data: result }
                    })
                })
        }

        if (locationList?.data?.length === 0 && !dependencies) {
            getData('location', setLocationList)
            getData('currency', setCurrencyList)
        }
        if ('building' === type && ['floor', 'wing', 'zone', 'subzone', 'area'].includes(listType)) getData('building', setBuildingList)
        if ('floor' === type && ['wing', 'zone', 'subzone', 'area'].includes(listType)) getData('floor', setFloorList)
        if ('zone' === type && ['subzone', 'area'].includes(listType)) getData('zone', setZoneList)
        if ('subzone' === type) getData('subzone', setSubZoneList)
        if ('wing' === type) getData('wing', setWingList)
    }

    useEffect(() => {
        apiCalls()
    }, [])


    const reset = (type) => {
        // console.log({ type });
        if (['location'].includes(type)) setBuildingList(defaultListvalues())
        if (['location', 'building'].includes(type)) setFloorList(defaultListvalues())
        if (['location', 'building', 'floor'].includes(type)) {
            setZoneList(defaultListvalues())
            setWingList(defaultListvalues())
        }
        if (['location', 'building', 'floor', 'zone'].includes(type)) setSubZoneList(defaultListvalues())
    }

    useEffect(() => {
        if (id) {

            GET(`${process.env.REACT_APP_URL}${listType}/${id}`)
                .then((response) => {
                    const result = response?.data
                    const prefillSelected = (field, method) => {
                        method((prevState) => {
                            return { ...prevState, selected: result[field]?._id ? result[field]?._id : "" }
                        })
                    }
                    // apiCalls('location', null)
                    if (result?.location?._id) apiCalls('building', `location=${result?.location?._id}`)
                    if (result?.building?._id) apiCalls('floor', `building=${result?.building?._id}`)
                    if (result?.floor?._id && tabList.includes('zone')) apiCalls('zone', `floor=${result?.floor?._id}`)
                    if (result?.floor?._id && tabList.includes('wing')) apiCalls('wing', `floor=${result?.floor?._id}`)
                    if (result?.zone?._id) apiCalls('subzone', `zone=${result?.zone?._id}`)

                    prefillSelected('location', setLocationList)
                    if (['floor', 'zone', 'subzone', 'wing', 'area'].includes(listType)) prefillSelected('building', setBuildingList)
                    if (['zone', 'subzone', 'wing', 'area'].includes(listType)) prefillSelected('floor', setFloorList)
                    if (['subzone', 'area'].includes(listType) && tabList.includes('zone')) prefillSelected('zone', setZoneList)
                    if (['area'].includes(listType) && tabList.includes('subzone')) prefillSelected('subzone', setSubZoneList)
                    if (['area'].includes(listType) && tabList.includes('wing')) prefillSelected('wing', setWingList)
                    if (listType === 'building') prefillSelected('currency', setCurrencyList)
                    setName(result?.name)
                })
        }
    }, [id, listType])

    const handleChange = (e) => {
        setName(e.target.value)
        setFormErrors({ ...formErrors, name: '' })

    }

    const handleIndexChange = (e) => {
        // setFloorIndex(e.target.value)
    }
    const cancelClick = () => {
        if (listType === 'tenant') {
            handleCancel()
        } else {
            props?.history?.push(`/list/${listType}`)
        }
    }

    const clearForm = () => {
        setName("")
    }

    const runValidations = () => {
        const errors = {}
        if (name.trim().length === 0) {
            errors.name = "Name cannot be empty"
        } else if (name.trim().length < 2) {
            errors.name = "Name should be mininum 2 characters"
        }
        if (['building', 'floor', 'zone', 'subzone', 'wing', 'area'].includes(listType) && !locationList.selected) errors.location = 'Please select the location'
        if (['floor', 'zone', 'subzone', 'wing', 'area'].includes(listType) && !buildingList.selected) errors.building = 'Please select the building'
        if (['zone', 'subzone', 'wing', 'area'].includes(listType) && !floorsList.selected) errors.floor = 'Please select the floor'
        if (['subzone', 'area'].includes(listType) && !zoneList.selected && tabList.includes('zone')) errors.zone = 'Please select the zone'
        if (['area'].includes(listType) && !subZoneList.selected && tabList.includes('subzone')) errors.subzone = 'Please select the subzone'
        if (['area'].includes(listType) && !wingList.selected && tabList.includes('wing')) errors.wing = 'Please select a wing'
        if (listType === 'building' && !currencyList.selected) errors.currency = 'Please select the currency'
        return errors
    }

    const handleSelectChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        if (name === "buildingIDSelect") {
            const data = { selected: value, data: [...buildingList.data] }
            setBuildingList(data)
            reset('building')
            apiCalls('floor', `building=${value}`)
            setFormErrors({ ...formErrors, building: '' })
        }
        else if (name === "locationIDSelect") {
            const data = { selected: value, data: [...locationList.data] }
            setLocationList(data)
            reset('location')
            apiCalls('building', `location=${value}`)
            setFormErrors({ ...formErrors, location: '' })
        } else if (name === "floorIDSelect") {
            const data = { selected: value, data: [...floorsList.data] }
            setFloorList(data)
            reset('floor')
            tabList.includes('zone') ? apiCalls('zone', `floor=${value}`) : apiCalls('wing', `floor=${value}`)
            setFormErrors({ ...formErrors, floor: '' })
        } else if (name === "zoneIDSelect") {
            const data = { selected: value, data: [...zoneList.data] }
            setZoneList(data)
            reset('zone')
            apiCalls('subzone', `zone=${value}`)
            setFormErrors({ ...formErrors, zone: '' })
        } else if (name === "subzoneIDSelect") {
            const data = { selected: value, data: [...subZoneList.data] }
            setSubZoneList(data)
            setFormErrors({ ...formErrors, subzone: '' })
        } else if (name === "wingIDSelect") {
            const data = { selected: value, data: [...wingList.data] }
            setWingList(data)
            setFormErrors({ ...formErrors, wing: '' })
        } else if (name === "currencyIDSelect") {
            const data = { selected: value, data: [...currencyList.data] }
            setCurrencyList(data)
            setFormErrors({ ...formErrors, currency: '' })
        }
    }


    const handleSubmit = () => {
        const errors = runValidations()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {

            setFormErrors({})

            const formObj = {
                name: name.trim()
            }

            if (['building', 'floor', 'zone', 'subzone', 'wing', 'area'].includes(listType)) formObj.location = locationList.selected
            if (['floor', 'zone', 'subzone', 'wing', 'area'].includes(listType)) formObj.building = buildingList.selected
            if (['zone', 'subzone', 'wing', 'area'].includes(listType)) formObj.floor = floorsList.selected
            if (['subzone', 'area'].includes(listType) && tabList.includes('zone')) formObj.zone = zoneList.selected
            if (['area'].includes(listType) && tabList.includes('subzone')) formObj.subzone = subZoneList.selected
            if (['area'].includes(listType) && tabList.includes('wing')) formObj.wing = wingList.selected
            if (listType === 'building') formObj.currency = currencyList.selected


            // if (listType === 'floor') formObj.index = Number(floorIndex)
            if (id) {

                PUT(`${process.env.REACT_APP_URL}${listType}/${id}`, formObj, listType)
                    .then((response) => {
                        clearForm()
                        props.history.push(`/list/${listType}`)
                    })

            } else {

                POST(`${process.env.REACT_APP_URL}${listType}`, formObj, listType)
                    .then((response) => {
                        clearForm()
                        setName('')
                    })
            }

        } else {
            setFormErrors(errors)
        }
    }

    let selectFields = []
    for (let i = 0; i < index; i++) {
        const field = tabList[i], label = `Select ${field}`, name = `${field}IDSelect`, labelId = `${field}Label`
        let list = []
        if (field === 'location') list = locationList
        if (field === 'building') list = buildingList
        if (field === 'floor') list = floorsList
        if (field === "zone") list = zoneList
        if (field === 'wing') list = wingList
        if (field === 'subzone') list = subZoneList

        selectFields.push(
            <FormControl fullWidth sx={{ maxWidth: "450px" }} key={i}>
                <InputLabel id={labelId} error={!!formErrors[field]}>Select {field} *</InputLabel>
                <Select
                    labelId={labelId}
                    id="demo-simple-select"
                    error={!!formErrors[field]}
                    value={list.selected}
                    label={label}
                    name={name}
                    onChange={handleSelectChange}
                >
                    {list.data.map((item) => {
                        return (
                            <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                        )
                    })}
                </Select>
                <FormHelperText error>{formErrors[field] ? formErrors[field] : null}</FormHelperText>
            </FormControl>
        )
        if (listType === 'building') {
            selectFields.push(
                <FormControl fullWidth sx={{ maxWidth: "450px" }} key={i + '$'}>
                    <InputLabel id="currencyLabel" error={!!formErrors['currency']}>Select Currency *</InputLabel>
                    <Select
                        labelId="currencyLabel"
                        id="demo-simple-select"
                        error={!!formErrors['currency']}
                        value={currencyList.selected}
                        label="Select Currency"
                        name="currencyIDSelect"
                        onChange={handleSelectChange}
                    >
                        {currencyList?.data?.map((item) => {
                            console.log({ item })
                            return (
                                <MenuItem value={item._id} key={item._id}>{item.acronym} - {item.name}</MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText error>{formErrors['currency'] ? formErrors['currency'] : null}</FormHelperText>
                </FormControl>
            )
        }
    }

    return (
        <Box sx={{ width: "100%", height: listType === 'tenant' || index === -1 ? "70vh" : `${(index + 4) * 14}vh`, display: "flex", justifyContent: "center", backgroundColor: "#F1F3F4" }} mt={20}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "60%", width: "40%", backgroundColor: "white", boxShadow: "2px 2px 4px gray" }} border="solid 1px #f1f1f1" borderRadius={2}>
                <Typography
                    variant="h5" fontWeight="bold"
                >{id ? "Edit this" : "Add a New"} {listType.charAt(0).toUpperCase() + listType.slice(1)}</Typography>

                {selectFields}

                <TextField
                    value={name}
                    onChange={handleChange}
                    label={`Enter ${listType} Name`}
                    fullWidth sx={{ maxWidth: "450px" }}
                    helperText={formErrors.name}
                    error={!!formErrors.name}
                    autoFocus
                />
                <Box sx={{ display: 'flex', justifyContent: "space-evenly", width: "30%" }}>
                    <Button
                        variant="filled"
                        onClick={handleSubmit}
                        sx={{
                            mr: 4,
                            backgroundColor: "#1976D2",
                            color: "white",
                            ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                    >{listType === 'tenant' ? "Next" : "Confirm"}</Button>
                    <Button
                        variant="filled"
                        sx={{
                            backgroundColor: "#333333",
                            color: "white",
                            ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                        onClick={cancelClick}
                    >Cancel</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default withRouter(MasterListForm)